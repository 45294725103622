import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Utilities extends Component {
    render() {
        return (
            <div>
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li>Thanh toán hóa đơn</li>
                        </ol>
                        <h2></h2>
                    </div>
                </section>
                <section id="utilities" className="portfolio-details portfolio-details-payment">
                    <div className="container" data-aos="fade-up">
                        <h4>Thanh toán hóa đơn</h4>
                        <br />
                        <div className="row portfolio-info">
                            {/* <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Di động</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Điện thoại cố định/Internet</p>
                                    </div>
                                </Link>
                            </div> */}
                            <div className="col-md-3 col-sm-6 col-6">
                                <Link to="elictric-bill">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Điện</p>
                                    </div>
                                </Link>
                            </div>
                            {/* <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Nước</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Bảo hiểm</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Vé Online</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 col-6">
                                <Link to="s1">
                                    <div className="img-box">
                                        <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                        <p>Truyền hình cáp</p>
                                    </div>
                                </Link>
                            </div> */}
                        </div>

                        {/* <div className="row">
                            <Link to="s1" className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bxl-dribbble"></i> Điện</div>
                                </div>
                            </Link>

                            <Link to="s2" className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-file"></i> Nước</div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </section>
            </div>
        );
    }
}
export default Utilities;
