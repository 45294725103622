import PDF from "./PDF"

const Privacy = () => {
    return (
        <>
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    {/* <h1 className="logo me-auto"><a href="/">Galaxy-pay</a></h1> */}
                    <a href="/" className="logo me-auto"><img src="https://static.galaxypay.vn/galaxypay-website/logo-cty-GP.png" alt="logo" className="img-fluid" /></a>

                </div>
            </header>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li>Chính sách quyền riêng tư</li>                 
                    </ol>
                    <h2></h2>
                </div>
            </section>                                <PDF url="https://drive.google.com/file/d/1kU8Ss4ft3QD81AaVqg74YH1D1uxnoxHe/preview" />

            {/* <div className="container t_c">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <article className="pd-term">
                            <h1 className="post-title text-center">CHÍNH SÁCH QUYỀN RIÊNG TƯ</h1>
                            <p className="text-justify">
                                Chính sách quyền riêng tư (“Chính sách”) là chính sách mô tả các quyền riêng tư của
                                khách hàng sử dụng dịch vụ Ví điện tử và/hoặc các dịch vụ khác (“Dịch Vụ”) do Công ty
                                TNHH Galaxy Pay cung cấp (“Khách hàng”) liên quan đến việc Công ty TNHH Galaxy Pay
                                (“Galaxy Pay/Công ty”) tiếp nhận, thu thập, xử lý, sử dụng, tiết lộ, lưu trữ, chia sẻ và
                                bảo vệ thông tin cá nhân của Khách hàng. Chính sách này điều chỉnh việc Khách hàng sử
                                dụng dịch vụ của Galaxy Pay, áp dụng cho các thông tin đăng tải trên các website của
                                Galaxy Pay và tất cả các phương tiện, công cụ, ứng dụng, dịch vụ liên quan đến việc cung
                                ứng dịch vụ của Galaxy Pay (gọi chung là Nền tảng).
                            </p>
                            <p className="text-justify">
                                Bằng việc sử dụng Dịch Vụ, đăng ký một tài khoản với Galaxy Pay hoặc truy cập Nền tảng,
                                bạn xác nhận và đồng ý rằng bạn chấp nhận các phương pháp, yêu cầu, và/hoặc chính sách
                                được mô tả trong Chính sách quyền riêng tư này, và theo đây bạn đồng ý cho phép Galaxy
                                Pay thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như mô tả tại
                                đây.<u><i> Nếu bạn không đồng ý cho phép thu thập và xử lý thông tin cá nhân của bạn như
                                mô tả trong chính sách này, vui lòng không sử dụng các dịch vụ của Galaxy Pay hay truy
                                cập nền tảng hoặc trang web của Galaxy Pay.</i></u>
                            </p>

                            <p className="text-justify">
                                Galaxy Pay có thể điều chỉnh Chính sách bất kỳ thời điểm nào thông qua việc đăng tải lên
                                trang website: https://galaxypay.vn/ và ứng dụng Galaxy Pay của Galaxy Pay. Bản điều
                                chỉnh có hiệu lực kể từ thời điểm được đăng tải. Bằng việc tiếp tục sử dụng Dịch vụ của
                                Galaxy Pay sau khi có các sửa đổi, cập nhật hoặc điều chỉnh Chính Sách này, điều đó đồng
                                nghĩa với việc Khách hàng đồng ý và chấp nhận Chính sách của Galaxy Pay.
                            </p>

                            <h2 className="text-justify title-term"><strong>1. Thông tin Khách hàng</strong></h2>
                            <p className="text-justify">
                                Thông Tin Khách Hàng theo Chính sách này được hiểu là (i) thông tin cá nhân của khách
                                hàng và (ii) tất cả các thông tin khác liên quan đến Khách hàng, được Galaxy Pay tiếp
                                nhận, thu thập, xử lý, sử dụng, lưu trữ, chia sẻ trong quá trình Khách hàng truy cập và
                                sử dụng dịch vụ của Galaxy Pay.
                            </p><p className="text-justify">
                            Thông tin cá nhân là những thông tin quan trọng và bắt buộc để xác định danh tính một cá
                            nhân/tổ chức cụ thể, bao gồm nhưng không giới hạn bởi: (i) thông tin định danh của cá nhân
                            (họ và tên, ngày/tháng/năm sinh, CMND/Căn cước công dân/Hộ chiếu, dữ liệu sinh trắc học và
                            các thông tin khác theo quy định của pháp luật…) hoặc thông tin định danh của tổ chức (Giấy
                            chứng nhận đăng ký doanh nghiệp, thời gian thành lập, chủ sở hữu, người đại diện và các
                            thông tin khác theo quy định của pháp luật…); (ii) thông tin liên lạc (điện thoại đi động,
                            địa chỉ email), thông tin tài khoản ngân hàng và các thông tin chi tiết cần thiết khác theo
                            quy định của pháp luật và Galaxy Pay.
                        </p><p className="text-justify">
                            Khách hàng phải cung cấp và cập nhật thông tin cá nhân cho Galaxy Pay một cách trung thực,
                            chính xác. Khách hàng cam kết đảm bảo tính chính xác, đầy đủ của thông tin cá nhân đã cung
                            cấp cho Galaxy Pay và kịp thời cập nhật ngay thông tin cá nhân khi có thay đổi. Galaxy Pay
                            không chịu trách nhiệm giải quyết bất cứ tranh chấp/khiếu nại nào nếu thông tin cá nhân
                            Khách hàng cung cấp có sự sai lệch, không chính xác hoặc giả mạo.
                        </p><p className="text-justify">
                            Trừ khi nhận được thông báo của Khách hàng, Galaxy Pay sẽ tiếp tục cung cấp dịch vụ của
                            Galaxy Pay và/hoặc liên hệ đến Khách hàng qua các địa chỉ mà Khách hàng đã đăng ký. Mọi liên
                            lạc của Galaxy Pay qua các thông tin liên lạc sau cùng của Khách hàng đã đăng ký với Galaxy
                            Pay sẽ được mặc định là đã gửi đến Khách hàng, bất kể việc Khách hàng có thực sự nhận được
                            hay không. Nếu thông tin của Galaxy Pay bị trả lại do địa chỉ liên lạc mà Khách hàng cung
                            cấp không chính xác, đã thay đổi nhưng chưa cập nhật hoặc không có giá trị sử dụng, ví điện
                            tử của Khách hàng vì thế sẽ không được phép hoạt động và Khách hàng không thể thực hiện bất
                            kỳ giao dịch nào thông qua dịch vụ
                            của Galaxy Pay cho đến khi Galaxy Pay nhận được các nội dung cập nhật liên quan.
                        </p>

                            <h2 className="text-justify title-term"><strong>2. Thu thập thông tin</strong></h2>
                            <h5 className="text-justify "><strong>2.1. Mục đích của việc thu thập và sử dụng thông
                                tin</strong></h5>
                            <p>
                                Galaxy Pay và/hoặc Bên cung cấp dịch vụ cho Galaxy Pay sử dụng Thông Tin Khách Hàng để
                                vận hành, cung cấp và cải thiện Dịch Vụ, cho các mục đích sử dụng của Galaxy Pay (“Mục
                                Đích”) bao gồm:
                            </p>
                            <p className="text-justify "><i>a. Cung cấp, cá nhân hóa, duy trì và cải thiện Dịch Vụ</i>
                            </p>
                            <ul>
                                <li>
                                    Đăng ký, truy cập và sử dụng dịch vụ của Galaxy Pay;
                                </li>
                                <li>
                                    Xác thực thông tin người sử dụng;
                                </li>
                                <li>
                                    Xử lý giao dịch; gửi thông báo về các giao dịch của Khách hàng;
                                </li>
                                <li>
                                    Truy thu nợ, giải quyết tranh chấp, khiếu nại, hỗ trợ điều tra, quản lý rủi ro của
                                    Khách hàng trong quá trình sử dụng dịch vụ, ngăn chặn hoặc khắc phục các hành vi
                                    gian lận hay những hoạt động vi phạm, bất hợp pháp khác;
                                </li>
                                <li>
                                    Kiểm tra khả năng thanh toán, đối chiếu kiểm tra tính chuẩn xác của thông tin và xác
                                    thực thông tin với bên thứ ba;
                                </li>
                                <li>
                                    Quản lý và bảo vệ hạ tầng kỹ thuật thông tin của Galaxy Pay;
                                </li>
                                <li>
                                    Đảm bảo an ninh, bảo mật cho tài khoản của Khách hàng;
                                </li>
                                <li>
                                    Đánh giá và quản trị rủi ro Khách hàng;
                                </li>
                                <li>
                                    Lưu trữ, quản lý, dự phòng cho việc phục hồi sự cố hay cho các mục đích tương tự
                                    khác;
                                </li>
                                <li>
                                    Kích hoạt các tính năng cá nhân hóa trải nghiệm của Khách hàng;
                                </li>
                                <li>
                                    Các hoạt động khác cho mục đích cung cấp, cá nhân hóa, duy trì và cải thiện Dịch Vụ.
                                </li>
                            </ul>

                            <p className="text-justify "><i>b. Đảm bảo an toàn và an ninh</i> cho các Dịch Vụ của Galaxy
                                Pay và
                                cho tất cả Khách hàng khi sử dụng.</p>
                            <p className="text-justify ">
                                <i>c. Nghiên cứu phát triển và bảo mật:</i> Galaxy Pay có thể sử dụng Dữ Liệu Cá Nhân mà
                                Galaxy
                                Pay thu thập để thử nghiệm, nghiên cứu, phân tích và phát triển sản phẩm. Điều này cho
                                phép Galaxy Pay hiểu và phân tích nhu cầu và sở thích của bạn, bảo vệ dữ liệu cá nhân
                                của bạn, cải thiện và tăng cường an toàn và bảo mật cho các Dịch Vụ của Galaxy Pay, phát
                                triển các tính năng, sản phẩm và dịch vụ mới và tạo điều kiện phát triển các giải pháp
                                tài chính và bảo hiểm.
                            </p>
                            <p className="text-justify ">
                                <i>d. Tiếp Thị và Quảng Bá:</i> Tiến hành các hoạt động tiếp thị, quảng cáo, khảo sát và
                                nghiên
                                cứu thị trường, đo lường và đánh giá hiệu ứng của dịch vụ, cải thiện nội dung dịch vụ
                                qua các phương thức liên hệ từ Galaxy Pay bao gồm nhưng không giới hạn bởi email, tin
                                nhắn điện thoại, cuộc gọi và các phương thức khác mà Khách hàng có thể liên lạc với
                                Galaxy Pay; và
                            </p>

                            <p className="text-justify ">
                                <i>e. Mục đích pháp lý:</i> Galaxy Pay có nghĩa vụ pháp lý để thu thập, sử dụng hoặc lưu
                                trữ
                                Thông Tin Khách Hàng của quý khách trong một số trường hợp nhất định, bao gồm khi được
                                yêu cầu, được tư vấn, đề nghị hoặc đòi hỏi bởi các cố vấn pháp lý hoặc bất kỳ quy định
                                pháp luật, văn bản hoặc yêu cầu của chính phủ hay các cơ quan có thẩm quyền tại địa
                                phương hoặc nước ngoài, yêu cầu của cơ quan nhà nước có thẩm quyền bao gồm nhưng không
                                giới hạn nghĩa vụ công bố thông tin, báo cáo theo quy định pháp luật về khuyến mại, lưu
                                trữ hồ sơ, kiểm toán, điều tra và giải quyết khiếu nại hoặc tranh chấp, tuân thủ các
                                lệnh của tòa án hoặc các yêu cầu pháp lý, văn bản hoặc yêu cầu của chính phủ hoặc quy
                                định khác; thực thi điều khoản, điều kiện hoặc các thỏa thuận khác; và bảo vệ quyền hoặc
                                tài sản của Galaxy Pay trong trường hợp khiếu nại hoặc tranh chấp
                            </p>


                            <h5 className="text-justify "><strong>2.2. Phương thức thu thập thông tin của Khách
                                hàng</strong></h5>
                            <p>Các phương thức Galaxy Pay thu thập thông tin của Khách hàng bao gồm:</p>

                            <h6 className="text-justify "><strong>2.2.1. Thông qua việc Khách hàng cung cấp:</strong>
                            </h6>

                            <p>Thông tin Khách hàng cung cấp bao gồm những thông tin do Khách hàng cung cấp:</p>
                            <p className="text-justify ">a. trên biểu mẫu đăng ký mở tài khoản, ứng dụng Ví điện tử và
                                được cập nhật trong quá trình Khách hàng sử dụng dịch vụ. Các thông tin này có thể là:
                                các thông tin liên lạc của Khách hàng; các thông tin về các giao dịch và hoạt động của
                                Khách hàng; các thông tin tài chính như tài khoản ngân hàng và/hoặc số thẻ tín dụng mà
                                Khách hàng sử dụng hoặc liên kết với dịch vụ, hoặc</p>
                            <p className="text-justify ">b. thông qua liên lạc giữa Khách hàng và Galaxy Pay, chẳng hạn
                                thông qua các tương tác, phản hồi, lấy ý kiến, góp ý, trao đổi, thỏa thuận, giải quyết
                                tranh chấp v.v... giữa Khách hàng với nhân viên, nhà cung cấp, nhà thầu của Galaxy Pay
                                hoặc một bên thứ ba khác do Galaxy Pay chỉ định phát sinh trong quá trình Khách hàng sử
                                dụng dịch vụ của Galaxy Pay.</p>

                            <p className="text-justify "><i>Trong trường hợp Khách hàng cung cấp thông tin của bên thứ
                                ba, Khách hàng cam đoan và bảo đảm rằng (i) Khách hàng có và đã có được đầy đủ sự đồng ý
                                và chấp thuận của bên thứ ba đó đối với việc Khách hàng cung cấp thông tin cho GALAXY
                                PAY và đối với việc GALAXY PAY sử dụng các thông tin đó theo cách thức và quy định tại
                                chính sách này này, và các thông tin được cung cấp không thuộc các trường hợp thông tin
                                cấm cung cấp/tiết lộ theo quy định của pháp luật hiện hành hoặc các thỏa thuận bảo mật
                                mà khách hàng đã giao kết.</i></p>

                            <h6 className="text-justify "><strong>2.2.2. Thông qua hệ thống, thiết bị công nghệ thông
                                tin khi dịch vụ của Galaxy Pay được sử dụng:</strong></h6>

                            <p className="text-justify ">a. Galaxy Pay thu thập các thông tin, dữ liệu phát sinh hoặc
                                liên quan đến việc Khách hàng sử dụng Dịch vụ của Galaxy Pay, bao gồm nhưng không giới
                                hạn ở thông tin, dữ liệu về các bên cung cấp dịch vụ, hàng hóa cho Khách hàng, thông tin
                                giao dịch mà Khách hàng thực hiện (loại hàng hóa, dịch vụ, địa điểm, thời gian giao
                                dịch), phương thức thanh toán (không lưu giữ các dữ liệu quan trọng của thẻ thanh toán
                                được sử dụng cho việc thanh toán bao gồm số thẻ chi tiết, số CVV hoặc mã số xác thực
                                khác có giá trị pháp lý tương đương), thông tin thiết bị (như địa chỉ IP, hệ điều hành,
                                loại trình duyệt, thông số phần cứng, UDID, MEIDs, định vị, địa chỉ của trang web giới
                                thiệu (nếu có), số lần truy cập, phản hồi, tên tệp, phiên bản và nhận dạng quảng cáo và
                                các thông tin liên quan khác (nếu có)).</p>
                            <p className="text-justify ">b. Khi Khách hàng truy cập và sử dụng dịch vụ của Galaxy Pay,
                                Galaxy Pay (hoặc các công cụ theo dõi hoặc thống kê hoạt động của website do các đối tác
                                cung cấp) sẽ đặt một số cơ sở dữ liệu gọi chung là Cookies lên máy tính hoặc các thiết
                                bị khác của Khách hàng. Một trong số những Cookies này có thể tồn tại lâu để thuận tiện
                                cho Khách hàng trong quá trình sử dụng, ví dụ: lưu email của Khách hàng trong trang đăng
                                nhập để Khách hàng không phải đăng nhập lại v.v… Khách hàng có quyền từ chối nhận những
                                Cookies này nếu những trình duyệt của Khách hàng có chức năng từ chối đó, trừ khi các
                                Cookies được Galaxy Pay dùng để ngăn chặn gian lận và đảm bảo an toàn đối với các
                                website kết nối.</p>
                            <p className="text-justify ">c. Galaxy Pay có thể giám sát hoặc ghi âm các cuộc đàm thoại
                                với Khách hàng hoặc bất kì ai nhân danh Khách hàng. Khách hàng có thể sẽ cung cấp thông
                                tin thông qua việc truy cập thông tin cá nhân được lưu trữ bởi bên thứ ba, chẳng hạn như
                                bên cung cấp dịch vụ xác thực thông tin, các nhà cung cấp hàng hóa/dịch vụ, các trang
                                thông tin điện tử, mạng xã hội. Khách hàng đồng ý rằng Galaxy Pay hoàn toàn có thể thu
                                thập, lưu trữ và sử dụng các thông tin này.</p>
                            <p className="text-justify ">d. GALAXY PAY có thể đề nghị Khách hàng sử dụng thiết bị di
                                động hoặc ứng dụng, website được tối ưu hóa phù hợp. Các quy định của chính sách này
                                được áp dụng đối với việc truy cập, sử dụng các thiết bị, ứng dụng, website này. Khi
                                Khách hàng tải và sử dụng các ứng dụng hoặc khi truy cập những website nói trên của
                                GALAXY PAY, GALAXY PAY có thể nhận được các thông tin về định vị thiết bị của Khách
                                hàng. GALAXY PAY có thể sử dụng và căn cứ các thông tin này để cung cấp dịch vụ đến
                                Khách hàng (ví dụ những nội dung về quảng cáo, kết quả tìm kiếm hay những nội dung
                                khác…). Hầu hết các thiết bị di động đều có chức năng cho phép Khách hàng kiểm soát hoặc
                                vô hiệu hóa chức năng định vị, hoặc trong trường hợp cần thiết, Khách hàng có thể liên
                                hệ với nhà cung cấp, để từ đó kiểm soát việc thông tin cho bên thứ ba các thông tin
                                này.</p>

                            <h6 className="text-justify "><strong>2.2.3. Thông tin thu thập từ các nguồn khác:</strong>
                            </h6>
                            <p className="text-justify ">Galaxy Pay có thể thu thập thông tin Khách hàng từ các nguồn
                                khác, bao gồm nhà cung cấp dịch vụ, đối tác, các chương trình giới thiệu, khi Khách hàng
                                thêm thông tin của người thứ ba trong các cuộc khảo sát, điều tra, chương trình khuyến
                                mại hay các hình thức tương tự và các nguồn công khai có sẵn và phù hợp theo luật pháp
                                hiện hành.</p>

                            <h2 className="text-justify title-term"><strong>3. Chia sẻ Thông Tin Khách Hàng</strong>
                            </h2>
                            <h5 className="text-justify "><strong>3.1 Chia sẻ Thông Tin Cá Nhân với Khách hàng khác
                                và/hoặc đối tác của Galaxy Pay</strong></h5>
                            <p className="text-justify ">a. Galaxy Pay có thể cung cấp cho các bên tham gia giao dịch
                                và/hoặc các nhà cung cấp dịch vụ có liên quan đến giao dịch các thông tin cần thiết của
                                Khách hàng hoặc các thông tin cần thiết khác để xác thực thông tin, nâng cao độ tin cậy
                                và an toàn của giao dịch. Bên nhận được thông tin không được phép sử dụng thông tin nhận
                                được cho những mục đích không liên quan đến việc thực hiện giao dịch, trừ khi có sự đồng
                                ý bằng văn bản của Khách hàng và Galaxy Pay.</p>
                            <p className="text-justify ">b. Galaxy Pay cho phép bên thứ ba có quyền chấp nhận thanh toán
                                của Khách hàng hoặc gửi yêu cầu thanh toán đến Khách hàng thông qua việc sử dụng dịch vụ
                                của Galaxy Pay. Để thực hiện quyền này, bên thứ ba có thể chia sẻ thông tin của Khách
                                hàng cho Galaxy Pay (ví dụ địa chỉ email hay số điện thoại của Khách hàng) để Galaxy Pay
                                thông báo yêu cầu thanh toán cho Khách hàng hoặc thông báo khi Khách hàng thanh toán cho
                                Bên thứ 3. Galaxy Pay sử dụng các thông tin này để xác nhận Khách hàng được phép sử dụng
                                dịch vụ của Galaxy Pay hoặc để gửi đến Khách hàng thông báo về tình trạng giao dịch.</p>
                            <p className="text-justify ">c. Galaxy Pay có thể cung cấp thông tin khách hàng cho các nhà
                                cung cấp, chuyên gia tư vấn, đối tác tiếp thị, công ty nghiên cứu và nhà cung cấp dịch
                                vụ hoặc đối tác kinh doanh khác. Điều này bao gồm: bộ phận xử lý thanh toán và bộ phận
                                hỗ trợ; dịch vụ chống rửa tiền; nhà cung cấp dịch vụ máy chủ, dịch vụ công nghệ thông
                                tin, nhà cung cấp lưu trữ đám mây; đối tác quảng cáo và nhà cung cấp nền tảng quảng cáo;
                                nhà cung cấp phân tích dữ liệu; các đối tác nghiên cứu, bảo hiểm, ngân hàng bao gồm cả
                                những người thực hiện khảo sát hoặc dự án nghiên cứu hợp tác với Công Ty hoặc thay mặt
                                Công Ty.</p>
                            <p className="text-justify ">d. Trao đổi, cung cấp, chia sẻ Thông Tin Khách hàng với các
                                công ty con, công ty liên kết, các công ty thành viên trong mô hình nhóm công ty, chi
                                nhánh và các đơn vị có liên quan khác của Công Ty TNHH Galaxy Pay.</p>

                            <h5 className="text-justify "><strong>3.2 Chia sẻ thông tin cá nhân cho bên thứ ba
                                khác</strong></h5>
                            <p>Galaxy Pay có thể chia sẻ thông tin cá nhân của Khách hàng trong các trường hợp:
                            </p>

                            <p className="text-justify ">a. Cung cấp, chia sẻ thông tin cho tòa án, các cơ quan
                                có thẩm quyền theo quy định của pháp luật hoặc các yêu cầu phù hợp của bên thứ
                                ba khác đối với Galaxy Pay khi Galaxy Pay nhận định được rằng việc tiết lộ thông
                                tin cá nhân này là để ngăn chặn những thiệt hại vật chất hoặc thất thoát về tài
                                chính, báo cáo/thông báo về những hành vi đáng nghi ngờ, điều tra những vi phạm
                                đối với Điều khoản sử dụng.</p>
                            <p className="text-justify ">b. Nhằm ngăn ngừa gian lận và quản lý rủi ro, Galaxy
                                Pay cũng có thể sẽ chia sẻ những thông tin cần thiết đến các website, đơn vị
                                liên kết nói trên trong trường hợp Galaxy Pay đang áp dụng các biện pháp hạn chế
                                nào đó đối với tài khoản của Khách hàng căn cứ trên các tranh chấp, khiếu nại,
                                hoàn tiền hay các trường hợp liên quan khác.</p>
                            <p className="text-justify ">c. Các bên thứ ba khác khi có sự yêu cầu hoặc đồng ý
                                của Khách hàng.</p>

                            <h2 className="text-justify title-term"><strong>4. Bảo mật và lưu trữ thông tin</strong>
                            </h2>

                            <h5 className="text-justify "><strong>4.1. Phương thức Galaxy Pay bảo mật và lưu trữ thông
                                tin</strong></h5>
                            <p className="text-justify ">a. Galaxy Pay lưu giữ và bảo mật thông tin cá nhân của Khách
                                hàng tại các máy chủ hệ thống và được đảm bảo an toàn bằng hệ thống tường lửa
                                (firewall), đồng thời thiết lập và bảo vệ các kết nối trao đổi thông tin, giao dịch, sử
                                dụng công nghệ SSL.</p>
                            <p className="text-justify ">b. Khách hàng đồng ý rằng Galaxy Pay có quyền lưu trữ và
                                chia sẻ thông tin cá nhân của Khách hàng lên hệ thống lưu trữ đám mây và hệ thống
                                công nghệ thông tin đặt ngoài lãnh thổ Việt Nam.</p>
                            <p className="text-justify ">c. Khách hàng đồng ý rằng Galaxy Pay và các đối tác của
                                Galaxy Pay có quyền lữu trữ thông tin về các phương tiện thanh toán Khách hàng
                                đã sử dụng bao gồm thông tin về tài khoản ngân hàng, thẻ ATM, thẻ tín dụng và
                                các phương tiện thanh toán khác nhằm mục đích tạo công cụ thuận tiện cho Khách
                                hàng lựa chọn nguồn thanh toán khi sử dụng dịch vụ.</p>
                            <p className="text-justify ">d. Toàn bộ thông tin của Khách hàng khi truyền đi
                                trên mạng hay lưu trữ đều được mã hóa, áp dụng cơ chế bảo mật đường truyền,
                                chữ ký điện tử. Bên cạnh đó, các hoạt động tương tác trực tiếp với các máy
                                chủ (server) và máy móc đặt tại các trung tâm dữ liệu (Data Center) đều phải
                                tuân thủ chặt chẽ các yêu cầu về an toàn và bảo mật công nghệ thông tin theo
                                quy định của Ngân hàng nhà nước và các tiêu chuẩn an ninh, an toàn, bảo mật
                                của quốc tế khác.</p>
                            <p className="text-justify ">e. Galaxy Pay lưu trữ Thông Tin Khách Hàng để
                                đảm bảo cho quý khách khả năng sử dụng liên tục các Dịch Vụ, và lưu trữ
                                trong thời hạn cần thiết để thực hiện được các Mục Đích, hoặc theo quy
                                định của pháp luật (bao gồm cả cho mục đích thuế và kế toán), hoặc để
                                thực hiện các công việc khác như được thông báo trước cho quý khách.
                                Thời gian Galaxy Pay lưu giữ Thông Tin Khách Hàng cụ thể khác nhau tùy
                                thuộc vào từng Mục Đích. Khi Thông Tin Khách Hàng không còn cần thiết
                                cho việc cung cấp Dịch Vụ hoặc Mục Đích hoặc Công Ty không còn có mục
                                đích kinh doanh hoặc pháp lý để giữ lại Thông Tin Khách Hàng, Galaxy Pay
                                sẽ thực hiện các bước để ngăn chặn việc truy cập hoặc sử dụng Thông Tin
                                Khách Hàng cho bất kỳ mục đích nào ngoài việc tuân thủ theo Chính Sách
                                Quyền Riêng Tư này, hoặc cho mục đích an toàn, bảo mật, phát hiện và
                                phòng chống gian lận.</p>

                            <h5 className="text-justify "><strong>4.2. Trách nhiệm của Khách hàng</strong></h5>
                            <p className="text-justify ">a. Khách Hàng tuyệt đối không sử dụng các công cụ, chương trình
                                hay biện pháp nào khác để can thiệp trái phép vào hệ thống hay làm thay đổi cấu trúc dữ
                                liệu của bất kỳ Dịch Vụ nào, cũng như bất kỳ hành vi nào khác nhằm phát tán, cổ vũ cho
                                các hoạt động với mục đích can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống
                                Công Ty, cũng như các các hành vi phạm pháp luật Việt Nam. Trong trường hợp Galaxy Pay
                                phát hiện quý khách có hành vi vi phạm, Galaxy Pay có quyền chuyển thông tin về hành vi
                                vi phạm cho các cơ quan có thẩm quyền để xử lý theo quy định pháp luật.</p>
                            <p className="text-justify ">b. Khách Hàng có trách nhiệm bảo vệ thông tin tài khoản của
                                mình và không cung cấp bất kỳ thông tin nào liên quan đến tài khoản, mật khẩu hay các
                                phương thức xác thực (vd: OTP) truy cập trên các website, ứng dụng, phần mềm và các công
                                cụ khác (nếu có). Trong trường hợp cần khôi phục mật khẩu tài khoản, Khách hàng đồng ý
                                chủ động lựa chọn và cho phép bên thứ ba mà Khách hàng đã lựa chọn nhận mật khẩu sử dụng
                                một lần (OTP). Theo đó, Khách hàng đồng ý chia sẻ thông tin tài khoản của mình cho bên
                                thứ ba đó và tự chịu trách nhiệm với việc chia sẻ này</p>
                            <p className="text-justify ">c. Galaxy Pay không chịu trách nhiệm về những vấn đề phát sinh
                                khi quý khách sử dụng Dịch Vụ từ các công cụ, giao diện khác không phải là website, ứng
                                dụng, phần mềm chính thức, được phép sử dụng trong khuôn khổ của Dịch Vụ. Mặt khác, Dịch
                                Vụ có thể chứa các liên kết đến và từ dịch vụ của các mạng đối tác, công ty quảng cáo,
                                các công ty trực thuộc VietjetAir, Galaxy Digital Holding hoặc các đơn vị phụ thuộc của
                                chúng. Xin lưu ý rằng mỗi bên sẽ
                                có chính sách bảo mật riêng của mình và việc lưu trữ, bảo mật, sử dụng những thông tin
                                cung cấp cho các bên này nằm ngoài phạm vi quản lý của Công Ty. Do đó, Công Ty sẽ không
                                chịu bất kỳ trách nhiệm hoặc nghĩa vụ pháp lý nào đối với việc việc lưu trữ, bảo mật và
                                sử dụng những thông tin này. Vui lòng kiểm tra chính sách về quyền riêng tư, chính sách
                                bảo mật hoặc các chính sách tương tự của các đơn vị này trước khi gửi bất kỳ dữ liệu cá
                                nhân nào cho họ.</p>

                            <h2 className="text-justify title-term"><strong>5. Điều chỉnh thông tin cá nhân</strong>
                            </h2>
                            <p className="text-justify ">Khách hàng có quyền yêu cầu điều chỉnh thông tin cá nhân
                                và/hoặc điều chỉnh mục đích của việc thu thập và sử dụng thông tin của mình vào bất kỳ
                                lúc nào bằng cách (i) truy cập vào tài khoản của Khách hàng và kiểm tra hiện trạng nội
                                dung đã cài đặt; hoặc (ii) liên hệ tới bộ phận chăm sóc Khách hàng của Galaxy Pay qua
                                tổng đài 1900638021 hoặc email Hotro@galaxypay.vn để được hỗ trợ. Trường hợp Khách hàng
                                đóng tài khoản của mình, Galaxy Pay sẽ tiến hành đóng tài khoản đó nhưng có thể sẽ vẫn
                                lưu giữ thông tin cá nhân của Khách hàng trong một khoảng thời gian nhất định nhằm phục
                                vụ cho việc truy thu nợ, giải quyết tranh chấp, khắc phục sự cố, hỗ trợ điều tra, ngăn
                                ngừa rủi ro, tuân thủ Điều khoản sử dụng, chống lại hành vi xóa dấu vết và gian lận.</p>

                        </article>
                    </div>
                </div>
            </div> */}
        </>
    )
}


export default Privacy;
