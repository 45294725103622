import '../App.css';
const Blank = () => {
    return (
        <>
            <div className="container t_c text-center">
                <div className="spinner-wrapper">
                <span>giao dịch đang được xử lý ...</span>
                <div className="loader"></div>

                </div>
            </div>
        </>
    );
};

export default Blank;
