const CancelPayment = () => {
    return (
        <div>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li><a href="/payment">Thanh toán hóa đơn</a></li>
                            <li>Thông báo đơn hàng</li>
                        </ol>
                    </div>
                </section>
                <section className="portfolio-details portfolio-details-payment">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="portfolio-info">
                                    <h3>Hủy giao dịch <i className="bx bx-error-circle icon-error"></i></h3>
                                </div>   
                                <hr></hr>
                                <div className="float-end">
                                    <a href="/payment" className="btn btn-outline-primary me-1">Giao dịch khác</a>
                                    <a href="/" className="btn btn-warning">Quay về trang chủ</a>
                                </div>                              
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default CancelPayment;