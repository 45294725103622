import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { merchantKey, banks, SERVER_URL } from '../../data'
const PaymentInformation = (props) => {
    const { state } = useLocation();

    const [selectcardType, setCardType] = useState("atm");
    const [selectBank, setBank] = useState("970437");
    const [otp, setOTP] = useState(true);
    const [preloader, setPreloader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error-message");
    const [dataErrorMessage, setDataErrorMessage] = useState("");

    const handleChangecardType = (e) => {
        setCardType(e.target.value);
        const filter_bank = banks.filter(item => { if (item.type.includes(e.target.value)) { return true } })
        setBank(filter_bank[0].value)
    };

    const handleChangeBank = (e) => {
        setBank(e.target.value);
    };

    const handleCheckOtp = (e) => {
        setOTP(!otp);
    };

    const onPayment = (e) => {
        setPreloader(true)
        if (state.param) {
            createOrder(state.param, state.base, state.request_id)
        }
        setPreloader(false)
    }

    const createOrder = (input, base, request_id) => {
        var payload = {
            bills: input.bills,
            providerId: base.providerId,
            productId: base.productId,
            customerId: base.customerId,
            bankId: selectBank,
            totalBillAmount: input.totalBillAmount,
            token: localStorage.getItem('token_gpay'),
            request_id: request_id
        }
        axios.post(`${SERVER_URL}/createOrder`, payload).then((response) => {
            if (response.status === 200 && response.data != null) {
                const mdata = response.data
                const merror = response.data.error
                if (merror.status === 1) {
                    transaction(input, mdata.data, localStorage.getItem('token_gpay'), request_id)
                } else if (merror.status === 0) {
                    setDataErrorMessage(merror.message)
                    setErrorMessage('error-message-show')
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const transaction = (input, create_order, token, request_id) => {
        const bill = input.bills[0]
        var obj = {
            "merchantKey": merchantKey,
            "billNumber": bill.billId,
            "orderAmount": `${input.totalBillAmount}`,
            "orderCurrency": "VND",
            "orderDescription": `Thanh toan hoa don dien ${bill.month}`,
            "cardType": selectcardType,
            "bank": selectBank,
            "otp": otp,
            "request": "purchase",
            "token": "",
            "language": "vi",
            "client_ip_addr": '1.1.1.1',
            "address": bill.address,
            "customerName": bill.customerName,
            "month": bill.month,
            "create_order": create_order,
            "token2": token,
            "request_id": request_id
        }
        axios({
            method: 'post',
            port: 9001,
            url: `${SERVER_URL}/transaction`,
            data: obj,
            config: { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
        })
            .then(function (response) {
                if (response.status === 200 && response.data != null) {
                    if (response.data.responseCode === "200") {
                        window.location.href = response.data.responseData.endpoint;
                    } else if (response.data.responseCode === "404") {
                        alert("chưa tích hợp phương thức thanh toán này!")
                    }
                    setPreloader(false)
                }
            })
            .catch(function (response) {
                setPreloader(false)
            });
    }

    return (
        <div>
            <div id={(preloader === true)? 'preloader':''} className="waiting_overlay"></div>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li><a href="/payment">Thanh toán hóa đơn</a></li>
                        <li>Hình thức thanh toán</li>
                    </ol>
                </div>
            </section>
            <section id="billDetail" className="utilities">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h3>Chọn hình thức thanh toán</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 d-flex align-items-stretch">
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0 align-items-stretch">
                            <form>
                                <div className="form-group">
                                    <label>Card Type</label>
                                    <select className="form-control" name="cardType" id="cardType" value={selectcardType} onChange={handleChangecardType}>
                                        <option value="international">
                                            International Card (VISA, MASTER CARD, JCB,...)
                                        </option>
                                        <option value="atm">ATM</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Bank/Card Scheme</label>
                                    <select className="form-control" name="bank" value={selectBank} onChange={handleChangeBank}>
                                        {banks.map((e, key) => {
                                            if (e.type === selectcardType) {
                                                return (
                                                    <option key={key} type={e.type} value={e.value}>
                                                        {e.name}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="otp" name="otp" checked={otp} onChange={handleCheckOtp} />
                                    <label className="form-check-label">OTP</label>
                                </div>
                                <div className="my-3">
                                    <div className={errorMessage} dangerouslySetInnerHTML={{__html: dataErrorMessage}}/>
                                </div>
                                <br />
                                <button type="button" className="save btn button_pay" onClick={onPayment}>Thanh toán</button>
                            </form>
                        </div>
                        <div className="col-lg-3 d-flex align-items-stretch">
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PaymentInformation;