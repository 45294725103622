import Layout from "./Components/Layout";
import Home from "./Components/Home";
import Utilities from "./Components/Services/Utilities";
import BillDetail from "./Components/Services/BillDetail";
import CancelPayment from "./Components/Services/CancelPayment";
import SuccessPayment from "./Components/Services/SuccessPayment";
import PaymentInformation from "./Components/Services/PaymentInformation";
import ErrorPayment from "./Components/Services/ErrorPayment";
import ElectricBill from "./Components/Services/ElectricBill";
import TermsConditions from "./Components/TermsConditions";
import Blank from "./Components/Blank";
import Privacy from "./Components/Privacy";
import AML from "./Components/AML";
import Faq from "./Components/Faq";
import Manual from "./Components/Manual";
import PDF from "./Components/PDF";
import Notify from "./Components/Notify";
const Xroutes = [
    {
        element: <Layout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/payment', element: <Utilities /> },
            { path: '/payment/elictric-bill', element: <ElectricBill /> },
            { path: '/payment/bill_detail', element: <BillDetail /> },
            { path: '/payment/cancel', element: <CancelPayment /> },
            { path: '/payment/result', element: <SuccessPayment /> },
            { path: '/payment/payment_information', element: <PaymentInformation /> },
            { path: '/payment/error', element: <ErrorPayment /> },
        ]
    },
    {
        path: '',
        element: <Home />,
    },
    { path: '/terms_conditions', element: <TermsConditions /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/aml', element: <AML /> },
    { path: '/blank', element: <Blank /> },
    { path: '/pdf-faq', element: <PDF url="assets/pdf/FAQ.pdf"/> },
    { path: '/pdf-manual', element: <PDF url="assets/pdf/Manual.pdf"/> },
    { path: '/faq', element: <Faq /> },
    { path: '/manual', element: <Manual /> },
    { path: '/ipn/napas/confirm', element: <Blank /> },
    { path: '/notify', element: <Notify /> }

]

export default Xroutes;
