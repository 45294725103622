import FormContact from "./Services/FormContact";
const Home = () => {
  return (
    <>
      <section id="hero" className="align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-5" data-aos="fade-up" data-aos-delay="200">
              <h2>WELCOME TO GALAXY PAY</h2>
              <h1>GIẢI PHÁP THANH TOÁN SỐ HÀNG ĐẦU VIỆT NAM</h1>
              <p>Là Công ty thuộc Tập đoàn Sovico với nhiệm vụ cung cấp các giải pháp tư vấn chiến lược, phát triển các dự án chuyển đổi số các công ty thành viên và vận hành các dự án công nghệ mới.</p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" className="btn-get-started scrollto wow fadeInUp animated">Get Started</a>
              </div>
            </div>
            <div className="col-lg-7 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
              <img src="assets/img/hero-1.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <main id="main">

        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon color-fb7756"><i className="bx bx-money"></i></div>
                  <h4><a href="">Cổng Thanh toán</a></h4>
                  <p>Hỗ trợ đa dạng phương thức thanh toán cho Đối Tác thông qua Cổng thanh toán Galaxy Pay</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon color-facd60"><i className="bx bx-wallet"></i></div>
                  <h4><a href="">Ví điện tử</a></h4>
                  <p>Giải pháp Ví điện tử Galaxy Pay với sự tiện lợi, tích hợp nhiều sản phẩm dịch vụ mang lại sự trải nghiệm tuyệt vời cho Khách hàng</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon color-1ac0c6"><i className="bx bx-receipt"></i></div>
                  <h4><a href="">Thanh toán hóa đơn</a></h4>
                  <p>Dịch vụ Thanh toán hóa đơn của Galaxy Pay giúp Khách hàng Thanh toán hóa đơn nhanh chóng, an toàn, tiết kiệm thời gian</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                <div className="icon-box">
                  <div className="icon color-ee0979"><i className="bx bx-layer"></i></div>
                  <h4><a href="">Mã thẻ/ Topup điện thoại</a></h4>
                  <p>Dịch vụ mua Mã thẻ/ Topup giúp khách hàng nạp tiền điện thoại, nạp thẻ game dễ dàng, nhanh chóng và thuận tiện thông qua hệ thống Galaxy Pay</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 className="title">About Us</h2>
              <div className="title-bdr">
                <div className="left-bdr"></div>
                <div className="right-bdr"></div>
              </div>
            </div>
            <div className="row content">
              <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                <img src="assets/img/skills.png" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6">
                <h2>
                  Galaxy Pay sẽ trở thành nhà tiên phong kỹ thuật số về thanh toán của Sovico Group thông qua:
                </h2>
                <ul>
                  <li><i className="ri-check-line"></i> Đưa dịch vụ tài chính tới mọi tầng lớp khách hàng</li>
                  <li><i className="ri-check-line"></i> Kết nối hạ tầng trong cả Hệ sinh thái số</li>
                  <li><i className="ri-check-line"></i> Trở thành cánh tay nối dài, gắn vào các ứng dụng số của các công ty thành viên và đối tác chiến lược</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 className="title">Contact</h2>
              <div className="title-bdr">
                <div className="left-bdr"></div>
                <div className="right-bdr"></div>
              </div>
              <p>Hãy liên hệ với chúng tôi để được hỗ trợ nhanh chóng</p>
            </div>
            <div className="row">
              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <FormContact />
              </div>
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Trụ sở chính</h4>
                    <p>Tòa nhà Vietjet Plaza, 60A Trường Sơn, Phường 2, Quận Tân Bình, Thành Phố Hồ Chí Minh</p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email</h4>
                    <p>hotro@galaxypay.vn</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Hotline</h4>
                    <p>1900 638 021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
