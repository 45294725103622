import axios from "axios";
import { SERVER_URL } from '../../data'
const login = () => {
    return axios.get(`${SERVER_URL}/login`).then((response) => {
        if (response.status === 200 && response.data != null) {
            return Promise.resolve(JSON.parse(response.data));
        }
    }).catch((error) => {
        console.log(error)
    })
}
export default {login};