import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { electricity, SERVER_URL } from '../../data'
import { useForm } from "react-hook-form";

const ElectricBill = () => {
    const [customerCode, setCustomerCode] = useState("");
    const [providerId, setProviderId] = useState("");
    const [productId, setProductId] = useState("");
    const [errorMessage, setErrorMessage] = useState("error-message");
    const [dataErrorMessage, setDataErrorMessage] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const supplier = electricity
    const form = useRef();
    let navigate = useNavigate();

    const onChangeCustomerCode = (e) => {
        setCustomerCode(e.target.value);
    };

    const onChangeProvider = (e) => {
        setProviderId(e.target.value)
        const filter_bank = supplier.filter(item => { if (item.provider_id.includes(e.target.value)) { return true } })
        setProductId(filter_bank[0].product_id)
    }
    const onSubmit = (e) => {
        form.current.validateAll();
        let token = localStorage.getItem('token_gpay')
        if (token == null) {
            axios.get(`${SERVER_URL}/login`).then((response) => {
                if (response.status === 200 && response.data != null) {
                    var mdata = JSON.parse(response.data)
                    localStorage.setItem('token_gpay', mdata.data.token)
                    queryBill()
                }   
            }).catch((error) => {
                console.log(error)
            })
        } else {
            queryBill()
        }
    };

    const queryBill = () => {
        const params = {
            customerId: customerCode,
            productId: productId,
            providerId: providerId,
            token: localStorage.getItem('token_gpay')
        }
        axios.post(`${SERVER_URL}/queryBill`, params).then((response) => {
            if (response.status === 200 && response.data != null) {
                const mdata = response.data.data
                const merror = response.data.error
                if (merror.status === 1) {
                    if (mdata.returnCode === 0) {
                        navigate("/payment/bill_detail", { state: { data: mdata, request_id: response.data.request_id, base: params} });
                    } else {
                        setDataErrorMessage(mdata.responseMessage)
                        setErrorMessage('error-message-show')
                    }
                } else {
                    setDataErrorMessage(merror.message)
                    setErrorMessage('error-message-show')
                    localStorage.removeItem('token_gpay')
                    window.location.reload()
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li><a href="/payment">Thanh toán hóa đơn</a></li>
                        <li>Điện</li>
                    </ol>
                </div>
            </section>
            <section className="portfolio-details portfolio-details-payment">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <h4>Thanh toán hóa đơn điện</h4>
                            <div className="portfolio-info">
                                <div className="img-box">
                                    <img src="/assets/img/logo-square-transparent.png" alt="logo" className="img-services" />
                                    <p>Điện</p>
                                </div>

                                <Form onSubmit={handleSubmit(onSubmit)} ref={form} className="php-email-form">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <b>Chọn nhà cung cấp</b>
                                            <select name="supplier" {...register('supplier', { required: true })} className="form-select" value={providerId} onChange={onChangeProvider}>
                                                <option></option>
                                                {supplier.map((e, key) => {
                                                    return (
                                                        <option key={key} value={e.provider_id}>
                                                            {e.text}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errors.supplier && errors.supplier.type === "required" && (
                                                <span className="validate-form">Vui lòng chọn nhà cung cấp.</span>
                                            )}
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <b>Nhập mã khách hàng</b>
                                            <input type="text" placeholder="Ví dụ: PE12000033988" {...register('code', { required: true })} value={customerCode} onChange={onChangeCustomerCode} className="form-control" />
                                            {errors.code && errors.code.type === "required" && (
                                                <span className="validate-form">Vui lòng nhập mã khách hàng.</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className={errorMessage} dangerouslySetInnerHTML={{__html: dataErrorMessage}}/>
                                    </div>
                                    <br />
                                    <button type="submit" className="save btn button_pay">Kiểm tra hóa đơn</button>
                                </Form>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ElectricBill;