import '../App.css';
import PDF from "./PDF";

const Manual = () => {
    return (
        <>
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    {/* <h1 className="logo me-auto"><a href="/">Galaxy-pay</a></h1> */}
                    <a href="/" className="logo me-auto"><img src="https://static.galaxypay.vn/galaxypay-website/logo-cty-GP.png" alt="logo" className="img-fluid" /></a>

                </div>
            </header>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li>Hỗ trợ</li>
                        <li>Hướng dẫn sử dụng</li>                        </ol>
                    <h2></h2>
                </div>
            </section>
            <section>
                <div className="container" data-aos="fade-up">
                <PDF url = "https://drive.google.com/file/d/1cmKLPdgJ6ZDBaH-DanmuqNL1TI8--8eR/preview"/>

                      </div>
            </section>
        </>
    );
};

export default Manual;
