import '../App.css';
import { useState } from "react";
import { FAQ_DATA, FAQ_TITLE , FAQ_SUB_TITLE } from '../data';

const Plus = () => {
    return (
        <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                />
                <rect
                    x="6.0104"
                    y="10.9247"
                    width={12}
                    height={2}
                    rx={1}
                    fill="currentColor"
                />
            </svg>
        </span>
    )
};

const Minus = () => {
    return (
        <span className="svg-icon toggle-off svg-icon-1">
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                />
                <rect
                    x="10.8891"
                    y="17.8033"
                    width={12}
                    height={2}
                    rx={1}
                    transform="rotate(-90 10.8891 17.8033)"
                    fill="currentColor"
                />
                <rect
                    x="6.01041"
                    y="10.9247"
                    width={12}
                    height={2}
                    rx={1}
                    fill="currentColor"
                />
            </svg>
        </span>
    )
}

const ItemCollapse = (props) => {
    const { title, description, defaultShow, id } = props;
    const [expand, setExpand] = useState(defaultShow);
    const handleClickCollapseItem = () => {
        setExpand(!expand)
    }
      // Create a function to render description as HTML
    const renderDescriptionAsHTML = () => {
        return { __html: description };
    };
    return (
        <>
            <div
                className="d-flex align-items-center collapsible py-3 toggle mb-0"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${id}`}
                onClick={handleClickCollapseItem}
            >
                <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    {expand && <Plus />}
                    {!expand && <Minus />}
                </div>
                <h6 className="text-gray-700 fw-semibold  cursor-pointer mb-0">
                    {title}
                </h6>
            </div>
            <div id={`collapse-${id}`} className={`collapse fs-6 ms-1 ${defaultShow ? 'show' : ''}`}>
                {/* <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    {description}
                </div> */}
                <div className="mb-4 text-gray-600 fs-6 ps-10" dangerouslySetInnerHTML={renderDescriptionAsHTML()} />
            </div>
        </>
    )
}



const Faq = () => {
    const groupedFAQ = FAQ_DATA.reduce((acc, item) => {
        const { cate } = item;
        if (!acc[cate]) {
            acc[cate] = [];
        }
        acc[cate].push(item);
        return acc;
    }, {});


    return (
        <>
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    <a href="/" className="logo me-auto"><img src="https://static.galaxypay.vn/galaxypay-website/logo-cty-GP.png" alt="logo" className="img-fluid" /></a>

                </div>
            </header>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li>Hỗ trợ</li>
                        <li>Câu hỏi thường gặp</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="container" data-aos="fade-up">
                    <>
                        {/*begin::FAQ card*/}
                        <div className="card">
                            {/*begin::Body*/}
                            <div className="card-body p-lg-15">
                                {/*begin::Classic content*/}
                                <div className="mb-13">
                                    {/*begin::Intro*/}
                                    <div className="mb-15">
                                        {/*begin::Title*/}
                                        <h2 className="fs-2x mb-6">
                                            {/* Frequesntly Asked Questions */}
                                            {FAQ_TITLE}
                                        </h2>
                                        {/*end::Title*/}
                                        {/*begin::Text*/}
                                        <p className="fs-6 text-gray-600 mb-2">
                                            {FAQ_SUB_TITLE}
                                            {/* First, a disclaimer – the entire process of writing a blog post
                                            often takes more than a couple of hours, even if you can type eighty
                                            words as per minute and your writing skills are sharp. */}
                                        </p>
                                        {/*end::Text*/}
                                    </div>
                                    {/*end::Intro*/}
                                    {/*begin::Row*/}
                                    <div className="row mb-12">
                                        {Object.entries(groupedFAQ).map(([category, items]) => (
                                            <div className="col-md-6 pe-md-10 mb-10 mb-md-0" key={category}>
                                                <h4 className="text-gray-800 fw-bold mb-4 ">{category}</h4>
                                                {items.map((item) => (
                                                    <div className="m-0" key={item.id}>
                                                        <ItemCollapse {...item} />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}



                                    </div>
                                    {/*end::Row*/}
                                </div>
                                {/*end::Classic content*/}

                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::FAQ card*/}
                    </>

                    {/* <PDF url = "https://drive.google.com/file/d/160HAG6S5sL--6daU2bHkaGvIk3r50OkR/preview"/> */}


                </div>
            </section>
        </>
    );
};

export default Faq;
