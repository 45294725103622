const AML = () => {
    return (
        <>
            <div className="container t_c">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <article>
                            <h1 className="post-title text-center">QUY ĐỊNH VỀ PHÒNG CHỐNG RỬA TIỀN VÀ TÀI TRỢ KHỦNG
                                BỐ</h1>

                            <p className="text-justify">Quy định về phòng, chống rửa tiền của Công ty TNHH Galaxy Pay
                                (“Galaxy Pay/Công Ty”) được thiết lập nhằm cam kết:</p>
                            <ul>
                                <li>
                                    Tuân thủ đầy đủ theo các quy định về phòng, chống rửa tiền theo Pháp luật Việt Nam
                                    và quốc tế.
                                </li>
                                <li>
                                    Thực hiện các biện pháp nhận biết khách hàng, đánh giá rủi ro liên quan đến rửa
                                    tiền và tài trợ khủng bố.
                                </li>
                                <li>
                                    Phối hợp với cơ quan chức năng xử lý các giao dịch đáng ngờ, rủi ro liên quan đến
                                    rửa tiền, tài trợ khủng bố.
                                </li>
                                <li>
                                    Bảo vệ Khách hàng, Công ty cũng như Cán bộ Công nhân viên không trở thành nạn nhân
                                    hoặc vô tình tiếp tay cho hoạt động rửa tiền.
                                </li>
                            </ul>
                            <p>Theo đó, một số nội dung đáng chú ý của Quy định về phòng chống rửa tiền của Galaxy Pay như
                                sau:</p>

                            <p><strong>Chính sách chấp thuận khách hàng</strong></p>

                            <p className="text-justify">Galaxy Pay chỉ chấp nhận và cung ứng sản phẩm, dịch vụ cho các
                                tổ chức, cá nhân đáp ứng đầy đủ các điều kiện giao dịch theo quy định của pháp luật và
                                chính sách, quy định của Công ty.</p>

                            <p className="text-justify">Galaxy Pay không cung cấp sản phẩm, dịch vụ, không thực hiện
                                giao dịch trong đối với Khách hàng hoặc các bên liên quan trong giao dịch là tổ chức, cá
                                nhân trong (i) danh sách chỉ định hoặc liên quan đến vùng lãnh thổ thuộc diện cấm vận
                                trong các Nghị quyết của Hội Đồng Bảo An Liên Hiệp Quốc, (ii) Danh sách đen của Bộ Công
                                an, (iii) các danh sách khác từ các cơ quan nhà nước có thẩm quyền, (iii) các danh sách
                                cấm vận của các tổ chức quốc tế được áp dụng trong từng thời kỳ, (iv) các Khách hàng
                                không cung cấp đầy đủ hoặc cung cấp thông tin giả, (v) Khách hàng không có thực, (vi)
                                Khách hàng đã được xác định là đáng ngờ hoặc đang bị điều tra bởi các cơ quan nhà nước
                                có thẩm quyền liên quan đến rửa tiền/tài trợ khủng bố...</p>

                            <p><strong>Thu thập thông tin, nhận biết và cập nhật thông tin khách hàng (KYC)</strong></p>

                            <p className="text-justify">Việc nhận biết khách hàng (KYC) cho phép Galaxy Pay hiểu rõ hơn
                                về khách hàng, các hành vi cũng như các giao dịch của khách hàng, nhằm mục đích giảm
                                thiểu tối đa rủi ro các sản phẩm/dịch vụ của Galaxy Pay bị sử dụng vào mục đích rửa tiền
                                và tài trợ khủng bố.</p>

                            <p className="text-justify">Căn cứ trên đặc điểm của từng loại sản phẩm/dịch vụ và phù hợp
                                với quy định của pháp luật hiện hành mà Galaxy Pay có thể áp dụng linh hoạt quy trình
                                nhận biết khách hàng và thưc hiện thu thập các thông tin như (i) Thông tin nhận dạng của
                                khách hàng, (ii) Thông tin về Chủ sở hữu hưởng lợi, (iii) Thông tin về mục đích của
                                Khách hàng trong mối quan hệ với Công ty, (iv) Thông tin và tài liệu liên quan khi khách
                                hàng có tham gia thỏa thuận pháp lý (ủy thác, ủy quyền) khi thực hiện giao dịch... Ngoài
                                ra, trong suốt thời gian thiết lập, duy trì quan hệ giữa Công Ty với Khách hàng, Galaxy
                                Pay sẽ thực hiện việc cập nhật thông tin nhận biết Khách hàng khi (i) biết được thông
                                tin Nhận biết Khách hàng có sự thay đổi hoặc (ii) định kỳ ít nhất 01 (một) năm một lần
                                đối với Khách hàng có rủi ro cao.</p>

                            <p><strong>Giám sát, phát hiện giao dịch đáng ngờ</strong></p>

                            <p className="text-justify">Galaxy Pay đảm bảo trách nhiệm giám sát giao dịch khách hàng
                                nhằm kịp thời phát hiện các giao dịch đáng ngờ, giao dịch có rủi ro liên quan đến rửa
                                tiền, tài trợ khủng bố cũng như kịp thời áp dụng các biện pháp trì hoãn giao dịch, báo
                                cáo cơ quan chức năng theo quy định Pháp luật.</p>

                            <p><strong>Báo cáo</strong></p>

                            <p className="text-justify">Trong trường hợp (i) giao dịch của Khách hàng tồn tại một hoặc
                                nhiều dấu hiệu đáng ngờ theo quy định pháp luật, hoặc (ii) Galaxy Pay có căn cứ cho rằng
                                giao dịch đó có liên quan đến hoạt động rửa tiền và/hoặc tài trợ khủng bố, Galaxy Pay sẽ
                                thực hiện trách nhiệm báo cáo và trì hoãn giao dịch theo quy định pháp luật về Phòng,
                                chống Rửa tiền và các yêu cầu, hướng dẫn từ cơ quan có thẩm quyền.</p>

                            <p><strong>Lưu trữ, bảo mật thông tin</strong></p>

                            <p className="text-justify">Galaxy Pay lưu giữ hồ sơ giao dịch của khách hàng kể từ ngày mở tài khoản/ phát sinh giao
                            dịch gồm hồ sơ về nhận biết khách hàng, các chứng từ kế toán, các chứng từ/dữ liệu liên quan
                            đến giao dịch của khách hàng và báo cáo giao dịch đáng ngờ kèm chứng từ, tài liệu liên quan
                                ít nhất 05 năm, kể từ ngày kết thúc giao dịch hoặc ngày đóng tài khoản hoặc ngày báo cáo</p>
                        </article>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AML;
