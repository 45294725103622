import React from "react";
import { Outlet, NavLink } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    {/* <h1 className="logo me-auto"><a href="/">Galaxy-pay</a></h1> */}
                    <a href="/" className="logo me-auto"><img src="https://static.galaxypay.vn/galaxypay-website/logo-cty-GP.png" alt="logo" className="img-fluid" /></a>
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li>
                                <NavLink className="nav-link scrollto active" to="/#hero">Home</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link scrollto" to="/#about">About</NavLink>
                            </li>
                            <li className="dropdown"><a href="/#services"><span>Services</span> <i className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <NavLink className="nav-link drop-child" to="payment">Thanh toán hóa đơn</NavLink>
                                </ul>
                            </li>
                            <li>
                                <NavLink className="nav-link scrollto" to="/#contact">Contact</NavLink>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>
            <Outlet />
        </>
    );
};

export default Layout;