import axios from "axios";
import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import { useForm } from "react-hook-form";
import { SERVER_URL, CAPTCHA_CLIENT } from '../../data'
const FormContact = () => {
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState("loading");
    const [errorMessage, setErrorMessage] = useState("error-message");
    const [sendMessage, setSendMessage] = useState("sent-message");
    const onChangeCustomerEmail = (e) => {
        setCustomerEmail(e.target.value);
    };
    const onChangeCustomerPhone = (e) => {
        setCustomerPhone(e.target.value);
    };
    const onChangeCustomerName = (e) => {
        setCustomerName(e.target.value);
    };
    const onChangeSubject = (e) => {
        setSubject(e.target.value);
    };

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
    };



    const { register, handleSubmit, formState: { errors } } = useForm();
    const form = useRef();
    const onSubmit = (e) => {
        form.current.validateAll();
        setLoading("loading-show")
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(CAPTCHA_CLIENT, { action: 'send_email' }).then(token => {
                if (token) {
                    axios({
                        method: 'post',
                        url: `${SERVER_URL}/captcha`,
                        data: { "token": token }
                    }).then(function (response) {
                        var mdata = JSON.parse(response.data)
                        if (mdata.success == true) {
                            sendEmail()
                        }                       
                    }).catch(function (response) {
                        setLoading('loading')
                        setSendMessage('sent-message')
                        setErrorMessage('error-message-show')
                    });
                }
            });
        });
    };

    const sendEmail = () => {
        axios({
            method: 'post',
            url: `${SERVER_URL}/sendEmail`,
            data: { "email": customerEmail,"phone":customerPhone,"name":customerName, "subject": subject, "message": message }
        }).then(function (response) {
            setLoading('loading')
            if (response.data == "error") {
                setSendMessage('sent-message')
                setErrorMessage('error-message-show')
            } else {
                setErrorMessage('error-message')
                setSendMessage('sent-message-show')
            }
        }).catch(function (response) {
            setLoading('loading')
            setSendMessage('sent-message')
            setErrorMessage('error-message-show')
        });
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} ref={form} className="php-email-form">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Họ tên</label>
                    <input type="text" name="name" className="form-control" id="name"
                           value={customerName} onChange={onChangeCustomerName}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Email</label>
                    <input type="email" {...register('email', { required: true })} value={customerEmail} onChange={onChangeCustomerEmail} className="form-control" />
                    {errors.email && errors.email.type === "required" && (
                        <span className="validate-form">Vui lòng nhập Email.</span>
                    )}
                </div>
            </div>
            <div className="form-group">
                <label>Số điện thoại</label>
                <input type="text" className="form-control" name="phone" id="phone"
                       value={customerPhone} onChange={onChangeCustomerPhone}/>
            </div>
            <div className="form-group">
                <label>Tiêu đề</label>
                <input type="text" {...register('subject', { required: true })} value={subject} onChange={onChangeSubject} className="form-control" />
                {errors.subject && errors.subject.type === "required" && (
                    <span className="validate-form">Vui lòng nhập subject.</span>
                )}
            </div>
            <div className="form-group">
                <label>Nội dung tin nhắn</label>
                <textarea rows="5" {...register('message', { required: true })} value={message} onChange={onChangeMessage} className="form-control">
                </textarea>
                {errors.message && errors.message.type === "required" && (
                    <span className="validate-form">Vui lòng nhập message.</span>
                )}
            </div>
            <div className="my-3">
                <div className={loading}>Loading</div>
                <div className={errorMessage}>Send message Error.</div>
                <div className={sendMessage}>Your message has been sent. Thank you!</div>
            </div>
            <div className="text-center">
                <button type='submit'>Send message</button>
            </div>
        </Form>
    )
}

export default FormContact;
