import { useLocation, useNavigate } from "react-router-dom";
const BillDetail = (props) => {
    const { state } = useLocation();
    let navigate = useNavigate();
    const onPayment = () => (e) => {
        navigate("/payment/payment_information", { state: { param: state.data, request_id: state.request_id, base: state.base } });
    }
    return (
        <div>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li><a href="/payment">Thanh toán hóa đơn</a></li>
                        <li>Điện</li>
                    </ol>
                </div>
            </section>

            <section className="portfolio-details portfolio-details-payment">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <h4>Thông tin hóa đơn Điện</h4>
                            <div className="portfolio-info">
                                {state.data && state.data.bills.length > 0 && state.data.bills.map((e, key) => {
                                    return (
                                        <div className="row" key={key}>
                                            <div className="col-lg-12 mt-5 mt-lg-0 align-items-stretch box-payment">
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <strong>Tháng {e.month}:</strong>
                                                        <span className="badge-primary badge-pill">{e.amount} VND</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <strong>Mã thanh toán: </strong>
                                                        <span className="badge-primary badge-pill">{e.billId}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <strong>Mã khách hàng: </strong>
                                                        <span className="badge-primary badge-pill">{e.customerId}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <strong>Khách hàng: </strong>
                                                        <span className="badge-primary badge-pill">{e.customerName}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <strong>Địa chỉ: </strong>
                                                        <span className="badge-primary badge-pill">{e.address}</span>
                                                    </li>
                                                </ul>
                                                <br />
                                            </div>
                                            <hr />
                                        </div>
                                    );
                                })}
                                <p><strong>Tổng tiền thanh toán: {state.data.totalBillAmount} VND</strong></p>
                                <button type="button" className="save btn button_pay" onClick={onPayment()}>Tiếp tục</button>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BillDetail;