import PDF from "./PDF"

const TermsConditions = () => {
    return (
        <>
            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">
                    {/* <h1 className="logo me-auto"><a href="/">Galaxy-pay</a></h1> */}
                    <a href="/" className="logo me-auto"><img src="https://static.galaxypay.vn/galaxypay-website/logo-cty-GP.png" alt="logo" className="img-fluid" /></a>

                </div>
            </header>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li>Các điều khoản và điều kiện về sản phẩm/dịch vụ tại galaxy pay</li>                 
                    </ol>
                    <h2></h2>
                </div>
            </section>                                <PDF url="https://drive.google.com/file/d/1Dt3lJoh-GDm9pP6urjiWTjWWdN7vRqDA/preview" />
            {/* <div className="container t_c">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <article className="pd-term">
                            <h1 className="post-title text-center">CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ SẢN PHẨM/DỊCH VỤ TẠI GALAXY PAY</h1>
                            <p className="text-justify">
                                Người Sử Dụng (Bạn – là người đăng ký sử dụng dịch vụ được đề cập trong văn bản này) cần đọc và đồng ý với những Điều Khoản và Điều Kiện này trước khi sử dụng Sản Phẩm/Dịch Vụ.
                                CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ SẢN PHẨM/DỊCH VỤ TẠI GALAXY PAY (sau đây gọi tắt là “Điều Khoản Chung”) điều chỉnh các quyền và nghĩa vụ của Người Sử Dụng, với tư cách là khách hàng, khi sử dụng Sản Phẩm/Dịch Vụ do CÔNG TY TNHH GALAXY PAY (Chúng tôi – được đề cập trong văn bản này) cung cấp trên Ví Điện Tử Ví Galaxy Pay.
                            </p>
                            <h2 className="text-justify title-term"><strong>1. Giải thích từ ngữ</strong></h2>
                            <p className="text-justify">Trong Điều Khoản Chung này, các từ và thuật ngữ sau đây sẽ có nghĩa dưới đây trừ khi ngữ cảnh có yêu cầu khác: </p>
                            <p className="text-justify">I.1 <b>Galaxy Pay</b>: là Công ty TNHH Galaxy Pay được thành lập hợp lệ và hoạt động theo pháp luật của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam, có Giấy Chứng Nhận Đăng Ký Doanh Nghiệp số 0316368255 cấp lần đầu ngày 08/07/2020.  </p>
                            <p className="text-justify">I.2 <b>Ứng dụng Galaxy Pay</b>: là ứng dụng trên nền tảng di động do Galaxy Pay phát triển và vận hành nhằm cung ứng dịch vụ Ví điện tử và các dịch vụ trung gian thanh toán khác được Ngân hàng Nhà nước Việt nam cấp phép triển khai dưới thương hiệu Ví Galaxy Pay. </p>
                            <p className="text-justify">I.3 <b>Tài khoản Galaxy Pay</b>: là tài khoản điện tử trên hệ thống công nghệ thông tin của Galaxy Pay do khách hàng tạo lập và quản lý thông qua nhiều hình thức khác nhau, bao gồm nhưng không giới hạn bởi ứng dụng trên điện thoại di động và các hình thức khác để truy cập, sử dụng Sản Phẩm/Dịch Vụ, bao gồm cả Dịch vụ Ví điện tử và các dịch vụ trung gian thanh toán khác do Ví Galaxy Pay cung cấp. </p>
                            <p className="text-justify">I.4 <b>Khách hàng (KH) hoặc Người sử dụng</b>: là những cá nhân hoặc tổ chức đã sử dụng hoặc có nhu cầu sử dụng Ví điện tử Ví Galaxy Pay. </p>
                            <p className="text-justify">I.5 <b>Sản phẩm/Dịch vụ</b>: bao gồm dịch vụ được thực hiện Tại Điểm Giao Dịch (nếu có) và các dịch vụ trên Ứng Dụng Ví Galaxy Pay. </p>
                            <p className="text-justify">I.6 <b>Giao dịch</b>: là bất kỳ giao dịch nào của KH liên quan đến việc sử dụng Sản Phẩm/Dịch Vụ được cung cấp bởi Galaxy Pay.</p>
                            <p className="text-justify">I.7 <b>Bồi hoàn</b>: là yêu cầu phát sinh đối với giao dịch sử dụng phương thức thanh toán Ví Galaxy Pay dẫn đến việc xử lý theo các quy định về bồi hoàn</p>
                            <p className="text-justify">I.8 <b>Khiếu nại</b>: là trường hợp một giao dịch bị khách hàng khiếu nại với Galaxy Pay. </p>
                            <p className="text-justify">I.9 <b>Phương thức thanh toán</b>: là cách thức khách hàng sử dụng nguồn tiền khi thực hiện các giao dịch thanh toán. </p>
                            <p className="text-justify">I.10 <b>Biện pháp Xác Thực</b>: là các yếu tố xác thực mà Galaxy Pay sử dụng để xác thực định danh khách hàng bao gồm nhưng không giới hạn mật khẩu sử dụng một lần (One-Time Password), mật khẩu, đặc điểm sinh trắc học và các biện pháp xác thực khác được phép thực hiện theo Quy Định Pháp Luật. </p>
                            <p className="text-justify">I.11 <b>eKYC</b>: là phương thức xác thực khách hàng của Galaxy Pay, ứng dụng các giải pháp công nghệ và các hệ thống phần mềm phù hợp nhằm cung cấp dịch vụ mở tài khoản Ví điện tử trực tuyến cho khách hàng một cách an toàn, chính xác và nhanh chóng. </p>
                            <p className="text-justify">I.12 <b>Chứng từ điện tử</b>: là các dữ liệu, thông điệp điện tử, tin nhắn, ghi âm…được tạo lập và lưu tại hệ thống của Galaxy Pay thể hiện giao dịch của khách hàng đăng ký dịch vụ Ví Galaxy Pay và đã được khách hàng xác thực đăng ký theo quy định của Galaxy Pay. Chứng từ điện tử bao gồm nhưng không giới hạn các hợp đồng, Điều khoản chung, các điều khoản và điều kiện, đề nghị, thông báo, xác nhận, chỉ dẫn, thông tin, lệnh thanh toán, chỉ thị trực tuyến và các thông điệp dữ liệu khác liên quan tới việc giao kết hay thực hiện các giao dịch giữa Galaxy Pay và khách hàng. </p>
                            <p className="text-justify">I.13 <b>Đơn vị chấp nhận thanh toán (ĐVCNTT)</b>: là tổ chức, cá nhân kết nối với hệ thống cung ứng Dịch vụ TGTT cho cho phép Khách Hàng thanh toán hàng hóa, dịch vụ qua Dịch Vụ TGTT do Galaxy Pay cung ứng. </p>
                            <p className="text-justify">I.14 <b>Ngày làm việc</b>: là các ngày từ Thứ Hai đến Thứ Sáu, không bao gồm ngày nghỉ, lễ, Tết theo Quy Định Pháp Luật. </p>

                            <h2 className="text-justify title-term"><strong>2. Các điều kiện chấp thuận</strong></h2>
                            <p className="text-justify">- Điều kiện chung này quy định các điều khoản và điều kiện truy cập và sử dụng tài khoản Ví Galaxy Pay và các dịch vụ được cung cấp bởi Galaxy Pay. Bằng việc truy cập, tải về Ứng Dụng Ví Galaxy Pay hoặc các Ứng dụng có tích hợp với Ví Galaxy Pay, bạn đồng ý rằng bạn đã đọc và hiểu bản Điều khoản chung này và các dịch vụ tài khoản kèm theo. </p>
                            <p className="text-justify">- Nội dung Điều khoản chung có thể được điều chỉnh bất kỳ thời điểm nào, và những điều chỉnh đó sẽ có hiệu lực khi Galaxy Pay đã thực hiện đăng tải và cập nhật phiên bản trên website https://galaxypay.vn.  </p>
                            <p className="text-justify">- Bằng việc tiếp tục truy cập và sử dụng tài khoản Ví Galaxy Pay hoặc sử dụng các dịch vụ liên quan đến tài khoản Ví Galaxy Pay tại bất kỳ thời điểm nào, bạn chấp thuận rằng bạn đã đồng ý các quy định của Điều khoản chung này cũng như các chỉnh sửa (nếu có).  </p>
                            <p className="text-justify">- Bên cạnh việc thực hiện các quy định tại Điều khoản chung này, khi sử dụng dịch vụ Galaxy Pay, bạn có trách nhiệm tuân thủ các hướng dẫn của Galaxy Pay tại mục FAQ và các hướng dẫn liên quan đến việc quản lý và sử dụng Ví Galaxy Pay được đăng tải công khai trên https://galaxypay.vn.  </p>
                            <p className="text-justify">- Bằng việc sử dụng Tài khoản Ví Galaxy Pay và các dịch vụ tài khoản, bạn hiểu và đồng ý rằng Galaxy Pay chỉ cung ứng dịch vụ trung gian thanh toán theo quy định pháp luật với các điều kiện cung cấp dịch vụ phù hợp thực tế triển khai tại thời điểm bạn sử dụng dịch vụ.  </p>

                            <h2 className="text-justify title-term"><strong>3. Thuật ngữ, từ viết tắt</strong></h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Thuật ngữ/từ viết tắt </th>
                                        <th>Giải nghĩa </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>KH</td>
                                        <td>Khách hàng </td>
                                    </tr>
                                    <tr>
                                        <td>Galaxy Pay </td>
                                        <td>Công ty TNHH Galaxy Pay </td>
                                    </tr>
                                    <tr>
                                        <td>GTPL/GTTT </td>
                                        <td>Giấy tờ pháp lý/Giấy tờ tùy thân, bao gồm: Chứng minh nhân dân, Căn cước công dân, Hộ chiếu.  </td>
                                    </tr>
                                    <tr>
                                        <td>CMND  </td>
                                        <td>Chứng minh nhân dân   </td>
                                    </tr>
                                    <tr>
                                        <td>CCCD </td>
                                        <td>Căn cước công dân  </td>
                                    </tr>
                                    <tr>
                                        <td>HC </td>
                                        <td>Hộ chiếu  </td>
                                    </tr>
                                    <tr>
                                        <td>Ví Galaxy Pay </td>
                                        <td>Ứng dụng Ví điện tử của Galaxy Pay  </td>
                                    </tr>
                                    <tr>
                                        <td>ĐVCNTT </td>
                                        <td>Đơn vị chấp nhận thanh toán </td>
                                    </tr>
                                    <tr>
                                        <td>TGTT </td>
                                        <td>Trung gian thanh toán  </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2 className="text-justify title-term"><strong>4. Đăng ký, thiết lập, quản lý tài khoản Ví Galaxy Pay </strong></h2>
                            <p className="text-justify">4.1 <b>Đăng ký dịch vụ</b></p>
                            <i className="text-justify">4.1.1 <b>Thông tin cung cấp </b></i>
                            <p className="text-justify">- Để mở và sử dụng tài khoản Ví Galaxy Pay, bạn phải cung cấp và đảm bảo tính xác thực, đầy đủ, chính xác, và cập nhật thường xuyên đối với thông tin cá nhân. </p>
                            <p className="text-justify">- Tùy theo yêu cầu của loại tài khoản bạn đăng ký với Ví Galaxy Pay, bạn có trách nhiệm duy trì một số điện thoại (hoặc email) theo hướng dẫn cụ thể của Galaxy Pay tại FAQ, cho phép Ví Galaxy Pay có thể liên lạc với bạn thông qua phương thức sử dụng các thông điệp dữ liệu điện tử (dưới dạng tin nhắn, điện thoại hay hình thức khác tương đương). </p>
                            <p className="text-justify">- Bạn hiểu và đồng ý rằng các thông điệp dữ liệu điện tử được Ví Galaxy Pay gửi đến thông tin liên hệ nhưng bạn không nhận được vì số điện thoại, email bạn khai báo là không chính xác, hết hạn hay bị khóa bởi bên cung cấp dịch vụ hoặc vì một lý do nào đó, bạn không thể nhận được các thông điệp của chúng tôi, Ví Galaxy Pay vẫn sẽ được hiểu là đã cung cấp cho bạn các thông báo có liên quan. </p>

                            <i className="text-justify">4.1.2 <b>Phân loại tài khoản </b></i>
                            <p className="text-justify">Theo các yêu cầu quản lý rủi ro, yêu cầu pháp luật hiện hành và các mức độ xác thực tài khoản theo thông lệ hoạt động trung gian thanh toán, Galaxy Pay có thể phân loại tài khoản Ví Galaxy Pay thành các cấp độ khác nhau, cho phép chủ tài khoản Ví Galaxy Pay được sử dụng ở một phạm vi nhất định. </p>

                            <i className="text-justify">4.1.3 <b>Tài khoản Ví Galaxy Pay của ĐVCNTT (hoặc Ví Galaxy Pay Doanh nghiệp) </b></i>
                            <p className="text-justify">Nếu bạn mở một tài khoản Ví Galaxy Pay doanh nghiệp (tài khoản cho phép bạn thực hiện các hoạt động mua bán, cung ứng hàng hóa, dịch vụ sử dụng kênh thanh toán bằng Ví Galaxy Pay), bạn có trách nhiệm cung cấp cho Galaxy Pay các hồ sơ, tài liệu theo quy trình hướng dẫn mở tài khoản Ví Galaxy Pay doanh nghiệp, và thực hiện đầy đủ các bước xác minh thông tin để hoàn tất việc đăng ký (kể cả việc xác minh thông tin tài khoản doanh nghiệp) hay các yêu cầu khi bạn đề nghị cho phép thanh toán đối với một ứng dụng, website, thiết bị mới và/hoặc bất kỳ thời điểm nào Galaxy Pay nhận thấy phát sinh các nguy cơ rủi ro từ hoạt động của tài khoản Ví Galaxy Pay doanh nghiệp của bạn. Việc xác thực tài khoản Ví Galaxy Pay Doanh nghiệp (ĐVCNTT) được thực hiện theo hướng dẫn của Galaxy Pay tại FAQ . Khi bán hàng, cung ứng dịch vụ bạn còn phải tuân thủ quy định Chấp nhận thanh toán bằng Ví Galaxy Pay. </p>

                            <i className="text-justify">4.1.4 <b>Cập nhật thông tin </b></i>
                            <p className="text-justify">Khi có bất kỳ thay đổi thông tin nào, bạn có trách nhiệm thông báo cho Ví Galaxy Pay việc thay đổi trước ngày áp dụng thay đổi, theo các phương thức mà Ví Galaxy Pay hỗ trợ tại thời điểm phát sinh nhu cầu cập nhật thông tin của bạn có thể được thực hiện trực tiếp trên ứng dụng Ví Galaxy Pay hoặc hướng dẫn của Galaxy Pay tại FAQ tại thời điểm thực hiện cập nhật. </p>

                            <p className="text-justify">4.2 <b>Tên đăng nhập và mật khẩu </b></p>
                            <p className="text-justify">Bạn chịu trách nhiệm đối với:  </p>
                            <p className="text-justify">a) duy trì bảo mật tên đăng nhập và mật khẩu (bao gồm cả mã Pin, mật khẩu thanh toán và các yếu tố nhận diện tương ứng) của bạn;  </p>
                            <p className="text-justify">b) tất cả các giao dịch và hoạt động xảy ra dưới tên đăng nhập và mật khẩu của bạn</p>
                            <p className="text-justify">c) bất kỳ và tất cả các hậu quả của việc sử dụng hoặc lạm dụng tên đăng nhập, mật khẩu của bạn. </p>

                            <p className="text-justify">Tên đăng nhập và mật khẩu là một phần không tách rời tài khoản Ví Galaxy Pay. Bạn đảm bảo:
                                Không chia sẻ hoặc chỉ định, chuyển nhượng, ủy quyền cho người khác biết hay sử dụng tên đăng nhập và mật khẩu;
                                Chấp thuận rằng việc chia sẻ, chỉ định, ủy quyền hay chuyển nhượng tên đăng nhập và mật khẩu cho người khác (bao gồm cả việc sử dụng) có thể gây ra những thiệt hại không thể khắc phục được cho Galaxy Pay và bạn sẽ phải bồi thường cho Galaxy Pay bất kỳ thiệt hại, mất mát nào do kết quả của những hành động này gây ra;
                                Thông báo cho Galaxy Pay ngay lập tức về việc bị sử dụng trái phép tên đăng nhập/ mật khẩu của bạn hoặc bất kỳ vi phạm bảo mật nào khác;
                                Chịu trách nhiệm toàn bộ cho bất kỳ tổn thất hoặc thiệt hại phát sinh từ việc không tuân thủ các quy định này.
                            </p>

                            <p className="text-justify">4.3 <b>Xác thực thông tin </b></p>
                            <p className="text-justify">Các thông tin do bạn cung cấp có thể được Galaxy Pay xác thực với bên thứ ba khi Galaxy Pay thấy cần thiết. Bạn đồng ý hợp tác xác thực thông tin khi Galaxy Pay có yêu cầu hoàn tất xác thực. Các thông tin này được sử dụng để định danh bạn khi sử dụng Ví Galaxy Pay hoặc các dịch vụ liên quan. Galaxy Pay có quyền khóa phong tỏa, thực hiện các biện pháp ngăn chặn hay giới hạn quyền truy cập, sử dụng Ví Galaxy Pay hoặc các dịch vụ liên quan nếu không thể kiểm tra hay xác thực các thông tin bạn đã đăng ký. Tất cả thông tin liên quan sẽ tạo thành tập dữ liệu cá nhân của bạn và được quản lý điều chỉnh bởi Chính Sách quyền riêng tư của Ví Galaxy Pay.
                                Nếu bạn là một ĐVCNTT bạn đồng ý rằng:
                            </p>
                            <p className="text-justify">- Tất cả nhân viên, đại lý, người đại diện và những người khác có quyền truy cập vào tên đăng nhập, mật khẩu của bạn đều đã được ủy quyền hợp lệ để sử dụng dịch vụ nhân danh bạn và ràng buộc bạn với bất kỳ giao dịch nào được ký kết trên hoặc thông qua web, app, thiết bị chấp nhận thanh toán bằng Ví Galaxy Pay.</p>
                            <p className="text-justify">- Chịu trách nhiệm toàn bộ về mọi hành động của các nhân viên, người đã từng là nhân viên, đại lý, người đại diện và những người khác truy cập vào tài khoản Ví Galaxy Pay của bạn hoặc các dịch vụ tài khoản sử dụng tên người dùng và mật khẩu của bạn, bất kỳ người nào đó đã được bạn ủy quyền hợp lệ.</p>

                            <p className="text-justify">4.4 <b>Chủ sở hữu hưởng lợi </b></p>
                            <p className="text-justify">Bạn đảm bảo rằng, bạn là chủ thể duy nhất sở hữu tài khoản và được hưởng lợi từ việc sử dụng tài khoản Ví Galaxy Pay của bạn.</p>

                            <p className="text-justify">4.5 <b>Khóa, Phong tỏa tài khoản Ví Galaxy Pay</b></p>
                            <i className="text-justify">4.5.1 Các trường hợp khóa, phong tỏa tài khoản Ví Galaxy Pay</i>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Các trường hợp </th>
                                        <th>Chấm dứt biện pháp khi </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            1.	Theo yêu cầu của Chủ tài khoản Ví Galaxy Pay hoặc Chủ tài khoản thông báo việc giao dịch trái phép hoặc nghi ngờ giao dịch trái phép theo quy định tại Điều khoản chung này;
                                            2.	Điều khoản chung trước giữa Galaxy Pay và chủ tài khoản Ví Galaxy Pay, trừ trường hợp chủ tài khoản chưa hoàn thành nghĩa vụ thanh toán theo quyết định cưỡng chế của cơ quan nhà nước có thẩm quyền (Galaxy Pay đã nhận được thông báo về việc này) hoặc chưa thanh toán xong các khoản nợ phải trả cho Galaxy Pay
                                            3.	Chủ tài khoản tự thực hiện hoạt động dẫn đến việc khóa tài khoản;
                                            Có yêu cầu bằng văn bản của cơ quan có thẩm quyền theo quy định của pháp luật, ngân hàng phát hành thẻ, ngân hàng liên kết;
                                            Galaxy Pay phát hiện thấy có nhầm lẫn, sai sót khi ghi Có nhầm vào tài khoản của khách hàng hoặc theo yêu cầu hoàn trả lại tiền của Galaxy Pay chuyển tiền do có nhầm lẫn, sai sót so với lệnh thanh toán của người chuyển tiền;
                                            Chủ tài khoản bị chết, bị tuyên bố đã chết, bị mất tích hoặc mất năng lực hành vi dân sự và Galaxy Pay có các thông tin xác thực.
                                            Chủ tài khoản là tổ chức bị chấm dứt hoạt động;
                                            Tài khoản vi phạm quy định cấm của Điều khoản chung sử dụng Ví Galaxy Pay.
                                        </td>
                                        <td>Chấm dứt việc khóa phong tỏa khi:
                                            1.	Nếu thuộc trường hợp 1, 2 thì việc chấm dứt khóa, phong tỏa tài khoản khi Galaxy Pay có Điều khoản chung với chủ tài khoản Ví Galaxy Pay;
                                            2.	Nếu thuộc trường hợp có yêu cầu của cơ quan nhà nước có thẩm quyền, ngân hàng thanh toán thì chỉ chấm dứt việc khóa, phong tỏa tài khoản Ví Galaxy Pay theo yêu cầu của các cơ quan này;
                                            3.	Nếu thuộc trường hợp 3, Chủ tài khoản nếu có nhu cầu phải thực hiện các thủ tục mở khóa tài khoản, thực hiện các bước định danh và liên kết tài khoản ngân hàng với tài khoản Ví Galaxy Pay;
                                            4.	Trường hợp 5 thì việc chấm dứt khóa, phong tỏa tài khoản được thực hiện sau khi xử lý xong sai sót, nhầm lẫn về chuyển tiền;
                                            5.	Các trường hợp 6, 7, 8, Galaxy Pay sẽ:
                                            -	Thông báo cho chủ tài khoản, người giám hộ hoặc người thừa kế hợp pháp biết;
                                            -	Chi trả theo quyết định của tòa án, cơ quan có thẩm quyền;
                                            -	Xử lý theo quy định pháp luật nếu các bên liên quan không nhận lại khoản tiền, hoặc nếu chủ tài khoản có Điều khoản chung bằng văn bản với Galaxy Pay trước đó;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p className="text-justify"><b>Ghi chú:</b>
                                Nếu chủ tài khoản Ví Galaxy Pay chết, tuyên bố mất tích, mất năng lực hành vi dân sự, khó khăn trong nhận thức làm chủ hành vi thì người tiếp nhận là người giám hộ hợp pháp, người thừa kế, đại diện thừa kế.
                                Việc khóa, phong tỏa tài khoản Ví Galaxy Pay sẽ được áp dụng đối với toàn bộ tài khoản.
                            </p>

                            <i className="text-justify">4.5.2 Các phương thức khóa, phong tỏa tài khoản Ví Galaxy Pay</i>
                            <p className="text-justify">- Việc khóa, phong tỏa tài khoản thực hiện theo hướng dẫn của Galaxy Pay tại FAQ</p>
                            <p className="text-justify">- Nếu bạn tự nguyện khóa, phong tỏa tài khoản Ví Galaxy Pay, bạn đảm bảo rằng, bạn đã rút toàn bộ số dư tài khoản Ví Galaxy Pay trước khi thực hiện các thao tác liên quan. Nếu vẫn còn số dư, bạn có thể yêu cầu Galaxy Pay hỗ trợ và đảm bảo đáp ứng đầy đủ các yêu cầu của Galaxy Pay trong từng trường hợp cụ thể.</p>

                            <i className="text-justify">4.5.3 Các ràng buộc sau khi khóa, phong tỏa tài khoản Ví Galaxy Pay</i>
                            <p className="text-justify">- Bạn không thể tránh khỏi trách nhiệm đối với việc quản lý, sử dụng tài khoản Ví Galaxy Pay diễn ra trước thời điểm hoàn tất việc khóa, phong tỏa tài khoản.</p>
                            <p className="text-justify">- Nếu bạn khóa, phong tỏa tài khoản của mình trong khi đang tiến hành các hoạt động điều tra, xác minh một vụ việc mà tài khoản của bạn có liên quan, Galaxy Pay có thể giữ khoản tiền của bạn để bảo vệ Ví Galaxy Pay hoặc bên thứ ba nhằm hạn chế các rủi ro về chuyển đổi tài khoản, giao dịch bồi hoàn, khiếu nại, và các khoản phí, tiền phạt, hình phạt và các trách nhiệm pháp lý khác.</p>
                            <p className="text-justify">- Bạn vẫn phải chịu trách nhiệm về tất cả các nghĩa vụ liên quan đến tài khoản của bạn ngay cả sau khi tài khoản của bạn đã hoàn tất các thủ tục khóa, phong tỏa tài khoản.</p>

                            <p className="text-justify">4.6 <b>Trách nhiệm của bạn </b></p>
                            <p className="text-justify">- Đảm bảo việc sử dụng Ví Galaxy Pay hoặc các dịch vụ tài khoản vào mục đích hợp pháp và tuân thủ tất cả các quy định Điều khoản chung này và pháp luật có liên quan;</p>
                            <p className="text-justify">- Các thông tin và thiết bị bạn cung cấp khi kết nối với Ví Galaxy Pay hoặc sử dụng các dịch vụ của bạn là đúng, đầy đủ, và chính xác và không vi phạm, gian dối hay lừa đảo;</p>
                            <p className="text-justify">- Không sử dụng Ví Galaxy Pay hoặc các dịch vụ tài khoản để lừa dối Galaxy Pay hoặc bất kỳ người nào khác, hoặc tham gia vào bất kỳ hoạt động bất hợp pháp (bao gồm cả các sản phẩm hoặc dịch vụ bị pháp luật cấm);</p>
                            <p className="text-justify">- Tự chịu trách nhiệm cho việc thanh toán bất kỳ khoản thuế, phí, lệ phí hay các khoản thu khác của nhà nước hay bất kỳ khoản tài chính nào có thể được áp dụng đối với bất kỳ sản phẩm hay dịch vụ nào được mua hay cung cấp thông qua các Dịch Vụ Tài Khoản hoặc các phát sinh liên quan từ giao dịch trực tuyến;</p>
                            <p className="text-justify">- Khi bạn tham gia hoạt động cung ứng hàng hóa dịch vụ, bạn phải tuân thủ các quy định, hướng dẫn chấp nhận thanh toán của Galaxy Pay .</p>

                            <p className="text-justify">4.7 <b>Các hoạt động bị cấm</b></p>
                            <p className="text-justify">Khi đăng ký, thiết lập, quản lý, sử dụng Dịch vụ hoặc trong quá trình tương tác với Ví Galaxy Pay, người sử dụng khác hoặc bên thứ ba, bạn sẽ không:</p>
                            <p className="text-justify">- Vi phạm Điều khoản chung này, các Điều khoản chung, Chính sách sử dụng được chấp nhận hoặc bất kỳ Chính sách nào khác mà bạn đã đồng ý với Galaxy Pay (việc đồng ý có thể bằng văn bản hoặc bằng hành vi thực tế là việc tiếp tục sử dụng Dịch vụ Ví Galaxy Pay sau khi Điều khoản chung, Chính sách đã chúng tôi thông báo đến bạn theo bất kỳ phương thức nào (đăng tải trên website, ứng dụng, điện thoại, email, ….);</p>
                            <p className="text-justify">- Vi phạm bất kỳ quy định pháp luật;</p>
                            <p className="text-justify">- Xâm phạm bản quyền, bằng sáng chế, nhãn hiệu, bí mật thương mại hoặc các quyền sở hữu trí tuệ khác, hoặc các quyền công khai hoặc riêng tư của Ví Galaxy Pay;</p>
                            <p className="text-justify">- Bán hàng giả mạo;</p>
                            <p className="text-justify">- Hành động mang tính phỉ báng, đe dọa hoặc quấy rối nhân viên của Galaxy Pay hoặc những người dùng khác;</p>
                            <p className="text-justify">- Cung cấp thông tin sai lệch, không chính xác hoặc gây hiểu nhầm;</p>
                            <p className="text-justify">- Tham gia hoạt động và / hoặc giao dịch có khả năng gian lận hoặc đáng ngờ;</p>
                            <p className="text-justify">- Từ chối hợp tác trong cuộc kiểm tra, xác minh và/hoặc cung cấp thông tin xác nhận danh tính của bạn hoặc bất kỳ thông tin nào bạn cung cấp cho chúng tôi;</p>
                            <p className="text-justify">- Nhận hoặc cố gắng nhận tiền từ cả Ví Galaxy Pay và Bên bán, ngân hàng hoặc Tổ chức phát hành thẻ cho cùng một giao dịch trong quá trình tranh chấp;</p>
                            <p className="text-justify">- Kiểm soát Tài khoản được liên kết với một Tài khoản khác đã tham gia vào bất kỳ Hoạt động Hạn Chế nào;</p>
                            <p className="text-justify">- Thực hiện hoạt động kinh doanh của bạn hoặc sử dụng Dịch vụ Ví Galaxy Pay theo cách dẫn đến hoặc có thể dẫn đến khiếu nại, tranh chấp, khiếu nại, bồi thường, bồi hoàn, phí, tiền phạt, hình phạt và trách nhiệm pháp lý khác đối với Ví Galaxy Pay, các Người dùng khác, các bên thứ ba hoặc bạn;</p>
                            <p className="text-justify">- Sử dụng Tài khoản Ví Galaxy Pay của bạn hoặc Ví Galaxy Pay theo cách mà Ví Galaxy Pay, Visa, MasterCard, JCB, American Express, Discover hoặc bất kỳ mạng lưới chuyển tiền điện tử nào khác có lý do tin là lạm dụng hệ thống thẻ hoặc vi phạm liên kết thẻ hoặc các quy tắc mạng lưới đó</p>
                            <p className="text-justify">- Sử dụng thẻ tín dụng với Tài khoản của bạn để rút một khoản tiền mặt (hoặc giúp người khác làm như vậy);</p>
                            <p className="text-justify">- Tiết lộ hoặc phân phối thông tin của người dùng khác cho bên thứ ba hoặc sử dụng thông tin cho các mục đích tiếp thị trừ khi bạn nhận được sự đồng ý rõ ràng của Người dùng để làm điều đó;</p>
                            <p className="text-justify">- Gửi email, tin nhắn không mong muốn đến người dùng hoặc sử dụng Ví Galaxy Pay để thu thập các khoản thanh toán cho việc gửi, hoặc hỗ trợ gửi, email không mong muốn cho các bên thứ ba;</p>
                            <p className="text-justify">- Thực hiện bất kỳ hành động nào áp đặt một tải trọng không hợp lý hoặc không cân xứng lên cơ sở hạ tầng của Galaxy Pay;</p>
                            <p className="text-justify">- Tạo thuận lợi cho bất kỳ virut, trojan, sâu hay các chương trình lập trình máy tính khác có thể gây hại, gây trở ngại, lén lút đánh chặn hoặc tước đoạt bất kỳ hệ thống, dữ liệu hoặc thông tin;</p>
                            <p className="text-justify">- Sử dụng một proxy ẩn danh; Sử dụng bất kỳ robot, nhện, thiết bị tự động khác, hoặc quá trình thủ công để giám sát hoặc sao chép trang website, ứng dụng của chúng tôi mà không có sự cho phép trước bằng văn bản của chúng tôi;</p>
                            <p className="text-justify">- Sử dụng bất kỳ thiết bị, phần mềm hoặc thói quen nào để loại trừ bất kỳ tính năng nào hoặc can thiệp hoặc cố can thiệp vào Ví Galaxy Pay;</p>
                            <p className="text-justify">- Thực hiện bất kỳ hành động nào có thể khiến chúng tôi bị hạn chế truy cập, quản lý, sử dụng bất kỳ dịch vụ nào từ các nhà cung cấp dịch vụ Internet, các tổ chức cung ứng dịch vụ thanh toán hoặc các nhà cung cấp khác; hoặc là</p>
                            <p className="text-justify">- Lạm dụng quy trình giải quyết tranh chấp, khiếu nại hoặc chính sách hỗ trợ người dùng Ví Galaxy Pay</p>

                            <h2 className="text-justify title-term"><strong>5. Quyền sở hữu trí tuệ </strong></h2>
                            <p className="text-justify"><b>5.1</b>
                                Tất cả các nhãn hiệu hàng hóa, logo, nhãn hiệu dịch vụ và tất cả các quyền sở hữu trí tuệ khác thuộc bất kỳ loại nào (cho dù đã được đăng ký hay chưa), và tất cả các nội dung thông tin, thiết kế, tài liệu, đồ họa, phần mềm, hình ảnh, video, âm nhạc, âm thanh, phức hợp phần mềm, mã nguồn và phần mềm cơ bản liên quan đến Galaxy Pay (gọi chung là “Quyền Sở Hữu Trí Tuệ”) là tài sản và luôn luôn là tài sản của Galaxy Pay và các tổ chức/đại lý khác được ủy quyền bởi Galaxy Pay (nếu có). Tất cả các Quyền Sở Hữu Trí Tuệ được bảo hộ bởi Quy Định Pháp Luật về bản quyền và các công ước quốc tế. Tất cả các quyền đều được bảo lưu.
                            </p>
                            <p className="text-justify"><b>5.2</b>
                                Ngoại trừ được cho phép rõ ràng trong những Điều Khoản Chung này, Người Sử Dụng không được sử dụng, biên tập, công bố, mô phỏng, dịch, thực hiện các sản phẩm phái sinh từ, phân phát hoặc bằng cách khác sử dụng, tái sử dụng, sao chép, sửa đổi, hoặc công bố Quyền Sở Hữu Trí Tuệ theo bất kỳ cách thức nào mà không có sự chấp thuận trước bằng văn bản của Galaxy Pay. Người Sử Dụng không được trợ giúp hoặc tạo điều kiện cho bất kỳ bên thứ ba nào sử dụng Quyền Sở Hữu Trí Tuệ theo bất kỳ cách thức nào mà cấu thành một vi phạm về sở hữu trí tuệ và/hoặc đối với các quyền liên quan khác của Galaxy Pay.
                            </p>

                            <h2 className="text-justify title-term"><strong>6.	Bồi hoàn </strong></h2>
                            <p className="text-justify">
                                Người Sử Dụng đồng ý bồi hoàn cho Galaxy Pay và các bên liên quan của Galaxy Pay và đảm bảo cho họ không bị thiệt hại bởi mọi tổn thất, khiếu nại, yêu cầu, khiếu kiện, thủ tục tố tụng, chi phí (bao gồm, nhưng không giới hạn, các chi phí pháp lý) và các trách nhiệm có thể phải gánh chịu hoặc đưa ra đối với Galaxy Pay và/hoặc các nhân viên, cán bộ… của Galaxy Pay phát sinh từ hoặc liên quan đến:
                            </p>
                            <p className="text-justify">-	Việc Người Sử Dụng sử dụng Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần nào của Sản Phẩm/Dịch Vụ); </p>
                            <p className="text-justify">-	Việc Người Sử Dụng vi phạm những Điều Khoản Chung này. </p>

                            <h2 className="text-justify title-term"><strong>7.	Bảo mật thông tin </strong></h2>
                            <p className="text-justify">
                                Việc thu thập, lưu giữ, sử dụng và chuyển giao dữ liệu của bạn được thực hiện theo Chính sách Quyền riêng tư.
                            </p>

                            <h2 className="text-justify title-term"><strong>8.	Xử lý vi phạm, thiệt hại </strong></h2>
                            <p className="text-justify">
                                <b>8.1.	Xử lý vi phạm</b>
                                Theo các quy định pháp luật hiện hành, bạn đồng ý bồi thường cho Galaxy Pay , nhân viên, người đại diện, bất kỳ tổn thất, thiệt hại, các khiếu nại, hành động và trách nhiệm pháp lý (bao gồm chi phí pháp lý cho việc bồi thường đầy đủ và toàn diện) có thể phát sinh, trực tiếp hoặc gián tiếp từ việc sử dụng Ví Galaxy Pay hoặc vi phạm Điều khoản chung này. Galaxy Pay có quyền với chi phí của mình đảm nhận việc bảo vệ và kiểm soát đối với bất kỳ khoản bồi thường, trong trường hợp đó bạn phải hợp tác với Galaxy Pay trong việc xác nhận bất kỳ biện pháp đảm bảo nào đang có.
                            </p>
                            <p className="text-justify">
                                <b>8.2.	Hành động của Galaxy Pay khi bạn thực hiện các hoạt động bị cấm</b>
                                Tùy theo quyết định của riêng mình, Galaxy Pay có thể thực hiện nhiều hành động khác nhau để bảo vệ Ví Galaxy Pay, bao gồm nhưng không giới hạn ở:
                                -	Khóa, phong tỏa hoặc giới hạn việc bạn truy cập vào Tài khoản hoặc Dịch vụ Ví Galaxy Pay;
                                -	Tạm ngưng việc tiến hành các biện pháp hỗ trợ bạn theo Điều khoản chung này và các Điều khoản chung, chính sách liên quan;
                                -	Từ chối cung cấp Dịch vụ Ví Galaxy Pay cho bạn ngay lập tức và trong tương lai;
                                -	Giữ tiền của bạn trong một khoảng thời gian cần thiết để khắc phục rủi ro có liên quan;
                                Galaxy Pay có thể giữ bất kỳ hoặc tất cả các khoản thanh toán bạn nhận được khi Galaxy Pay tin rằng có thể có rủi ro cao liên quan đến bạn, Tài khoản của bạn, hoặc bất kỳ hoặc tất cả các giao dịch của bạn. Quyết định của Ví Galaxy Pay có thể dựa trên các yếu tố khác nhau và thậm chí là thông tin nhận được từ các bên thứ ba. Trong trường hợp này, Galaxy Pay sẽ thông báo cho bạn thông qua các thông tin mà bạn đã cung cấp, việc tạm giữ này có thể kéo dài đến khi kết thúc sự kiện dẫn đến việc giữ khoản thanh toán như (a) Tranh chấp, Khiếu nại, bồi hoàn hoặc Thay đổi, (b) Bạn vi phạm Điều khoản chung này hoặc bất kỳ Chính sách nào khác dẫn đến nhu cầu tiếp tục nắm giữ các khoản tiền, hoặc (c) Bạn có thể tham gia vào Hoạt động và / hoặc giao dịch gian lận hoặc nghi ngờ.
                                -	Ngoài ra, nếu bạn vi phạm Quy định sử dụng Ví Galaxy Pay, ngoài các hành động trên, bạn sẽ phải chịu trách nhiệm với Galaxy Pay đối với toàn bộ thiệt hại phát sinh. Galaxy Pay có thể khấu trừ những thiệt hại đó trực tiếp từ số dư hiện có trong Tài khoản của bạn.

                            </p>
                            <p className="text-justify">
                                <b>8.3.	Giới hạn dịch vụ</b>
                                -	Mặc dù có bất kỳ hạn chế nào được mô tả ở quy định khác trong Điều khoản chung này, Galaxy Pay có thể thiết lập các thông lệ và giới hạn liên quan đến việc sử dụng Dịch Vụ, bao gồm các giới hạn giao dịch riêng lẻ hoặc tổng hợp về giá trị, số lượng trong bất kỳ khoảng thời gian cụ thể nào;
                                -	Galaxy Pay có quyền thay đổi, đình chỉ hoặc chấm dứt bất kỳ khía cạnh nào của Dịch Vụ vào bất kỳ thời điểm nào, bao gồm thời gian hoạt động hoặc tính sẵn có của Dịch Vụ hoặc bất kỳ tính năng Dịch Vụ nào mà không cần thông báo và không phải chịu trách nhiệm pháp lý;
                                -	Galaxy Pay cũng có quyền áp đặt giới hạn Dịch Vụ nhất định hoặc hạn chế quyền truy cập của bạn vào một số hoặc tất cả các Dịch Vụ mà không thông báo và không phải chịu trách nhiệm;
                                -	Galaxy Pay có thể từ chối tiến hành bất kỳ giao dịch nào mà không cần thông báo trước cho bạn. Đặc biệt, Galaxy Pay sẽ có quyền từ chối cung ứng Dịch Vụ cho bất kỳ giao dịch trực tuyến nào nếu:
                                •	Giao dịch trực tuyến không đáp ứng các điều khoản và điều kiện trong Điều khoản chung Cung Ứng Dịch Vụ hoặc các điều khoản, quy tắc, chính sách hiện hành khác có liên quan hoặc
                                •	Nếu Galaxy Pay có lý do để tin rằng giao dịch có thể vi phạm bất kỳ luật, quy tắc hoặc quy định nào hoặc có thể buộc Galaxy Pay phải chịu trách nhiệm.
                            </p>
                            <p className="text-justify">
                                <b>8.4.	Bảo trì và hoạt động của hệ thống cung ứng dịch vụ</b>
                                Nếu bạn không thể truy cập vào hoặc sử dụng dịch vụ do hoạt động không bình thường của hệ thống vì bất kỳ lý do nào sau đây, bạn đồng ý rằng, trong phạm vi pháp luật cho phép, bạn sẽ không buộc Galaxy Pay phải chịu trách nhiệm:
                                -	Bảo trì hệ thống đã được Galaxy Pay thông báo;
                                -	Không truyền tải dữ liệu do sự cố thiết bị hay hệ thống viễn thông;
                                -	Sự thất bại trong hoạt động của hệ thống do sự kiện bất khả kháng bao gồm nhưng không giới hạn ở các sự kiện: Bão, động đất, sóng thàn, lũ lụt, mất điện, chiến tranh, tấn công khủng bố hoặc ác sự kiện bất khả kháng khác vượt quá khả năng kiểm soát hợp lý của chúng tôi hoặc
                                -	Sự gián đoạn hoặc trì hoãn các Dịch vụ do hack, nâng cấp dịch vụ, yêu cầu của cơ quan có thẩm quyền ngăn cấm hoặc cản trở cung cấp Dịch vụ hoặc không thực hiện bởi sự kiện bất khả kháng.
                            </p>
                            <p className="text-justify">
                                <b>8.5.	Từ chối và giới hạn trách nhiệm</b>
                                -	Bạn đồng ý rằng tất cả các tranh chấp về hàng hóa dịch vụ từ các giao dịch trực tuyến là vấn đề duy nhất giữa bạn và đối tác đó;
                                -	Galaxy Pay sẽ không chịu trách nhiệm về bất kỳ thiệt hại gián tiếp, do hậu quả, ngẫu nhiên, đặc biệt hoặc trừng phạt, bao gồm thiệt hại về mất lợi nhuận hoặc doanh thu, gián đoạn kinh doanh, mất cơ hội kinh doanh, mất mát dữ liệu hoặc mất mát các lợi ích kinh tế khác, dù là trong hợp đồng, do sơ suất, sai lầm hay vì lý do khác phát sinh từ việc sử dụng hoặc không có khả năng sử dụng Dịch vụ.
                            </p>

                            <h2 className="text-justify title-term"><strong>9.	Quy định chung </strong></h2>
                            <p className="text-justify">
                                -	Điều khoản chung này sẽ được giải thích và thi hành theo luật Việt Nam. Bạn đồng ý không huỷ ngang thẩm quyền duy nhất của Toà án có thẩm quyền trong giải quyết tranh chấp có liên quan Điều khoản chung này;
                                -	Nếu bất kỳ nội dung nào của Điều khoản chung không hợp lệ, không giá trị hoặc không thể thi hành trừ khi được trích dẫn trực tiếp thì sẽ không ảnh hưởng đến tính hợp pháp, hiệu lực và thi hành của những điều khoản và điều kiện còn lại trừ khi được chỉ định và dẫn chiếu trực tiếp;
                                -	Việc chưa hoặc chậm trễ thực hiện quyền hạn và nghĩa vụ của Galaxy Pay theo Điều khoản chung này sẽ không được coi như là từ bỏ toàn bộ hay một phần quyền hạn và nghĩa vụ của mình trừ khi Galaxy Pay có thông báo bằng văn bản về sự khước từ quyền hạn và nghĩa vụ của mình.
                                -	Bất khả kháng
                                Sự Kiện Bất Khả Kháng là các sự kiện xảy ra một cách khách quan không thể lường trước được, mặc dù đã áp dụng mọi biện pháp cần thiết mà khả năng cho phép, bao gồm nhưng không giới hạn ở các sự kiện như thiên tai, hoả hoạn, lũ lụt, động đất, tai nạn, thảm hoạ, hạn chế về dịch bệnh, nhiễm hạt nhân hoặc phóng xạ, chiến tranh, nội chiến, khởi nghĩa, đình công hoặc bạo loạn, thì bên bị ảnh hưởng sẽ phải nhanh chóng thông báo cho bên kia bằng văn bản về việc không thực hiện được nghĩa vụ của mình do Sự Kiện Bất Khả Kháng, và sẽ, trong thời gian 15 (mười lăm) ngày kể từ ngày xảy ra Sự Kiện Bất Khả Kháng, chuyển trực tiếp bằng thư bảo đảm cho bên kia các bằng chứng về việc xảy ra Sự Kiện Bất Khả Kháng và khoảng thời gian xảy ra Sự Kiện Bất Khả Kháng đó.
                                Bên thông báo việc chịu ảnh hưởng của Sự Kiện Bất Khả Kháng có trách nhiệm phải thực hiện mọi nỗ lực để hoặc giảm thiểu thiệt hại gây ra bởi Sự Kiện Bất Khả Kháng đó.
                                -	Bạn thừa nhận rằng quyết định của Galaxy Pay để thực hiện một số hành động nhất định, bao gồm hạn chế quyền truy cập vào Tài khoản của bạn, Chặn hoặc Giữ khoản thanh toán, có thể dựa trên các tiêu chuẩn cần thiết cho việc quản lý rủi ro, bảo mật Tài khoản Người dùng và Hệ thống thanh toán. Bạn đồng ý rằng Ví Galaxy Pay không có nghĩa vụ tiết lộ các chi tiết về các sự kiện đó cho bạn.
                                -	Bạn phải chịu trách nhiệm cho tất cả các khoản hoàn lại, khoản bồi hoàn, khiếu nại, lệ phí, tiền phạt, tiền phạt và trách nhiệm pháp lý khác phát sinh bởi Ví Galaxy Pay, một Người sử dụng hoặc một bên thứ ba gây ra bởi hoặc phát sinh từ vi phạm Điều khoản chung này, và / hoặc việc bạn sử dụng Dịch vụ Ví Galaxy Pay. Bạn đồng ý hoàn trả Ví Galaxy Pay, một Người dùng hoặc một bên thứ ba cho bất kỳ và tất cả trách nhiệm đó.
                                -	Bất cứ hướng dẫn nào của bạn đã được chứng thực thì đó sẽ là căn cứ để Galaxy Pay có thể áp dụng. Galaxy Pay sẽ không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào của bạn hoặc bất kỳ ai khác mà Galaxy Pay đã hành động theo những hướng dẫn này.
                                -	Trong trường hợp bạn phải chịu trách nhiệm về bất kỳ khoản nợ nào với Galaxy Pay , Galaxy Pay có thể ngay lập tức ghi nợ số tiền đó khỏi số dư của bạn. Nếu bạn không có số dư đủ để bù đắp khoản nợ của mình, và bạn sẽ phải ngay lập tức Thêm tiền vào Số dư Ví Galaxy Pay của bạn hoặc hoàn trả Ví Galaxy Pay bằng phương pháp thay thế. Nếu bạn không làm như vậy, Ví Galaxy Pay có thể thực hiện các biện pháp cần thiết để thu hồi số tiền đó từ bạn.
                                -	Quy định khác
                                •	Nếu bất kỳ điều khoản nào của Điều khoản chung này bị coi là không hợp lệ hoặc không thể thi hành, điều khoản đó sẽ bị vô hiệu và các quy định còn lại sẽ vẫn có giá trị thi hành;
                                •	Các tiêu đề chỉ nhằm mục đích tham khảo và không ý nghĩa xác định, giới hạn, xây dựng hoặc mô tả phạm vi hay mức độ của phần đó;
                                •	Bất kỳ sự thất bại nào do Galaxy Pay thực hiện bất kỳ quyền nào theo Điều khoản chung này sẽ không tạo thành việc từ bỏ quyền đó hoặc miễn trừ đối với những vi phạm tương tự hoặc tương tự. Việc miễn trừ chỉ có hiệu lực nếu được lập thành văn bản;
                                •	Nếu bạn đang sử dụng Ví Galaxy Pay, Galaxy Pay sẽ cấp cho bạn quyền có thể huỷ ngang, không độc quyền và không thể chuyển nhượng để sử dụng Ví Galaxy Pay theo các tài liệu tương ứng. Bạn không được thuê, cho thuê hoặc chuyển giao các quyền của bạn cho bên thứ ba. Bạn phải tuân thủ các yêu cầu triển khai và sử dụng trong tất cả tài liệu Ví Galaxy Pay. Nếu bạn không tuân thủ, bạn sẽ phải chịu trách nhiệm về tất cả các thiệt hại mà bạn, Galaxy Pay và bên thứ ba phải chịu. Galaxy Pay có thể thay đổi hoặc chấm dứt bất kỳ tính năng nào mà không cần thông báo trước cho bạn. Bạn đồng ý không thay đổi, sao chép, chỉnh sửa, phân phối, hiển thị, xuất bản, dịch ngược, dịch, tháo rời, dịch ngược hoặc cố gắng tạo ra bất kỳ mã nguồn nào có nguồn gốc từ Ví Galaxy Pay. Bạn thừa nhận rằng tất cả các quyền, quyền sở hữu và lợi ích của Ví Galaxy Pay thuộc sở hữu của Galaxy Pay;
                                •	Galaxy Pay có quyền chuyển nhượng và chuyển giao, Điều khoản chung này (bao gồm tất cả các quyền, chức danh, lợi ích, lợi ích, nghĩa vụ của chúng tôi trong Điều khoản chung này) cho bất kỳ công ty liên kết nào của chúng tôi và bất kỳ người kế nhiệm nào. Galaxy Pay có thể ủy thác bất kỳ quyền và trách nhiệm của Galaxy Pay theo Điều khoản chung này cho các nhà thầu độc lập hoặc các bên thứ ba khác. Bạn không được chuyển nhượng toàn bộ hoặc một phần Điều khoản chung này cho bất kỳ cá nhân hoặc tổ chức nào.
                                -	Thông báo
                                •	Việc thông báo thực hiện theo một trong các phương thức sau: (i) trên website (ii) các phương tiện truyền thông đại chúng ; (iii) niêm yết công khai tại địa chỉ giao dịch của Galaxy Pay ; (iv) gửi thông tin cho Khách hàng thông qua đường bưu điện, email, fax, tin nhắn, điện thoại đã đăng ký; (v) Các phương thức khác đã được ĐVCNTT đồng ý;
                                •	Thông báo được xem như bạn đã nhận: (i) Trong vòng 03 (ba) ngày kể từ ngày gửi đi (theo dấu bưu điện đi), nếu gửi bằng thư; hoặc (ii) Vào ngày gửi đi nếu gửi bằng fax, tin nhắn tự động, email, điện thoại trực tiếp hoặc
                                •	Kể từ ngày đã nhận hoặc ngày hiệu lực của thông báo (nếu ngày hiệu lực của thông báo sau ngày bạn nhận) nếu không đồng ý, bạn có quyền yêu cầu chấm dứt sử dụng dịch vụ. Nếu bạn vẫn tiếp tục sử dụng dịch vụ sau ngày Galaxy Pay thông báo hoặc ngày hiệu lực của thông báo thì mặc nhiên được coi là bạn đồng ý và chấp thuận với các sự kiện đó.
                            </p>
                        </article>
                    </div>
                </div>

            </div> */}
        </>
    );
};

export default TermsConditions;