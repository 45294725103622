import { useSearchParams } from 'react-router-dom'
import axios from "axios";
import { SERVER_URL } from '../../data'
import AuthService from "../Actions/Login";
import React, { useState, useEffect } from "react";
import moment from 'moment';
const SuccessPayment = (props) => {
    const [searchParams] = useSearchParams();
    const order_id = searchParams.get('order_id')
    const [result, setResult] = useState("");
    const [metadata, setMetadata] = useState("")
    useEffect(() => {
        let token = localStorage.getItem('token_gpay')
        if (token == null) {
            AuthService.login().then((response) => {
                if (response.error.status === 1 && response.data !== null) {
                    localStorage.setItem('token_gpay', response.data.token)
                    window.location.reload()
                }
            })
        } else {
            getStatus()
        }
    }, []);

    const getStatus = () => {
        var payload = {
            order_id: order_id,
            token: localStorage.getItem('token_gpay')
        }
        axios.post(`${SERVER_URL}/order_status`, payload).then((response) => {
            if (response.status === 200 && response.data != null) {
                const mdata = response.data.data
                const merror = response.data.error
                if (merror.status === 1) {
                    setMetadata(mdata.metadata)
                    setResult(mdata)
                }
                if (merror.status === 0) {
                    AuthService.login().then((response) => {
                        if (response.error.status === 1 && response.data !== null) {
                            localStorage.setItem('token_gpay', response.data.token)
                            window.location.reload()
                        }
                    })
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li><a href="/payment">Thanh toán hóa đơn</a></li>
                            <li>Thông báo đơn hàng</li>
                        </ol>
                    </div>
                </section>
                <section className="portfolio-details portfolio-details-payment">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="portfolio-info">
                                    <h3>{result.title} {result.status === "failed" ? <i className="bx bx-error-circle icon-error"></i> : <i className="bx bx-check-circle icon-check"></i> }</h3>
                                    <div className="row">
                                        <div className="col-lg-4 col-6">Loại dịch vụ:</div>
                                        <div className="col-lg-4 col-6">{result.categoryName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-6">Đơn vị:</div>
                                        <div className="col-lg-4 col-6">{result.providerName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-6">Số tiền:</div>
                                        <div className="col-lg-6 col-6">{result.totalAmount}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-6">Thời gian:</div>
                                        <div className="col-lg-6 col-6">{ moment(result.createdAt).format('YYYY-MM-DD HH:mm:ss') }</div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="float-end">
                                    <a href="/payment" className="btn btn-outline-primary me-1">Giao dịch khác</a>
                                    <a href="/" className="btn btn-warning">Quay về trang chủ</a>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default SuccessPayment;